import React from 'react'
import FeaturedSession from '../../Components/FeaturedSession/FeaturedSession'
import FilterSection from '../../Components/FilterSection/FilterSection'
import Footer from '../../Components/Footer/Footer'
import HeroBanner from '../../Components/HeroBanner/HeroBanner'
import CustomeNavbar from '../../Components/Navbar/CustomeNavbar'
import styles from "./HomeScreen.module.css"


function HomeScreen() {

     return (
          <>
               <CustomeNavbar />
               <HeroBanner />
               <div className={`container-fluid ${styles.container__homescreen__one}`}>
                    <FeaturedSession />
               </div>
               <div className={`container-fluid ${styles.container__homescreen__one}`}>
                    <FilterSection />
               </div>
               <Footer />
          </>
     )
}

export default HomeScreen