const info = [
  {
    _id: "1",
    time: "01:00 PM",
    date: "Sat 01:00 PM - 1  Session",
    bigDate: "28 Oct, 01:00 PM - 01:30 PM",
    bannerImage: "./assets/images/Day_2/MicrosoftTeams-image (80).png",
    tag: "FIS & Innovation",
    heading: "Welcome & Key Note Session: FIS InnovateIN48 Partner Edition",
    description:
      "Embark on a journey of digital evolution with Vikash Sharma, Head of FIS India and Philippines Technology and Services. In this keynote for Day 1 of the FIS InnovateIN48 Partner Edition, Vikash will delve into the heart of technological change, driven by an unwavering mission to improve lives globally. Drawing from his vast experience in spearheading large-scale digital transformations, he offers a unique perspective on the importance of empowerment, customer centricity, and continuous innovation in the fintech space.",
    tutorialLink: "https://www.youtube.com/embed/hmHSc6Q1Oks",
    speaker: [
      {
        _id: "1",
        speakerName: "Vikash Sharma",
        designation:
          "Head, India and Philippines Technology and Service at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4120_6953_351845001659443843.png",
        linkedIn: "https://www.linkedin.com/in/vikash-sharma-933a8a19/",
      },
    ],
  },

  {
    _id: "2",
    time: "01:30 PM",
    date: "Sat 01:30 PM - 1  Session",
    bigDate: "28 Oct,01:30 PM - 01:45 PM",
    bannerImage: "./assets/images/Day_2/Frame 296 (1).png",
    tag: "FIS & Innovation",
    heading: "Introduction: FIS InnovateIN48 Partner Edition",
    description:
      "Kickstart your InnovateIN48 journey with this introductory session! As technological transformations redefine financial services, FIS InnovateIN48 Partner Edition emerges as the epicenter of fintech innovation within the FIS® sphere. Here, we'll provide a snapshot of the two-day program, linking visionary startups with established giants. Delve into what lies ahead, from interactive workshops to collaboration opportunities, and discover how we're paving pathways for collective growth in the fintech realm. Join us in setting the stage for a transformative two days!.",
    tutorialLink: "https://www.youtube.com/embed/AfFcWc3-K8Q",
    speaker: [
      {
        _id: "1",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
    ],
  },

  {
    _id: "3",
    time: "01:45 PM",
    date: "Sat 01:45 PM - 1  Session",
    bigDate: "28 Oct,01:45 PM - 02:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 286.png",
    tag: "Main Stage",
    heading: "FIS Global Innovation Report",
    description:
      "As the financial ecosystem evolves unprecedentedly, understanding the innovation landscape becomes paramount. In our exclusive FIS Global Innovation Report, we've tapped into the insights of 2,000 senior executives across sectors, unveiling pivotal answers that shape the future. This report paints a comprehensive picture, from identifying game-changing innovations to analyzing barriers and opportunities. Notably, the insights drawn are the bedrock and inspiration behind the FIS InnovateIN48 Partner Edition. Join us to decode the findings and grasp the future of fintech innovation.",
    tutorialLink: "https://www.youtube.com/embed/-99Hc931Opc",
    speaker: [
      {
        _id: "1",
        speakerName: "Kanv Pandit",
        designation: "SVP, Group Sales Executive at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4192_6583_091689001697000189.png",
        linkedIn: "https://www.linkedin.com/in/kanvpandit/",
      },
      {
        _id: "2",
        speakerName: "Samara Zwanger",
        designation:
          "Director, Signature Research and Content Strategy at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4575_8339_199347001697546511.png",
        linkedIn: "https://www.linkedin.com/in/samarazwanger/",
      },
      {
        _id: "3",
        speakerName: "Venkat Namachivayam",
        designation: "Director Development at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4542_5606_516621001696939118.png",
        linkedIn: "https://www.linkedin.com/in/venkat1999/",
      },
    ],
  },

  {
    _id: "4",
    time: "02:15 PM",
    date: "Sat 02:15 PM - 1  Session",
    bigDate: "28 Oct,02:15 PM - 02:45 PM",
    bannerImage: "./assets/images/Day_2/Frame 380.png",
    tag: "FIS & Innovation",
    heading:
      "Introduction to FIS LOBs (Lines of Business): Banking and Merchant Business",
    description:
      "Embark on a comprehensive exploration of the vital lines of business that underscore FIS's global impact. This panel discussion offers a panoramic view of FIS's core segments: Banking and Merchant Business. Engage with the distinguished FIS India leadership team, delve into the nuances of key product offerings, and map out their global footprints. Travel through the annals of FIS's growth and gaze into future product trajectories. For startups aspiring to thrive within the FIS ecosystem, this session will be instrumental in demystifying essential FIS terminologies and strategizing alignment.",
    tutorialLink: "https://www.youtube.com/embed/_GS7ZVDVWBs",
    speaker: [
      {
        _id: "1",
        speakerName: "Karthik Ranganathan",
        designation: "Development Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3044_6701_493902001696938476.png",
        linkedIn: "https://www.linkedin.com/in/karthikranganathan/",
      },
      {
        _id: "2",
        speakerName: "Bhaskar Karuppiah",
        designation: "Vice President IT Group Executive at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3457_8613_770470001695896933.png",
        linkedIn: "https://www.linkedin.com/in/bhaskar-karuppiah/",
      },
      {
        _id: "3",
        speakerName: "Vikash Sharma",
        designation:
          "Head, India and Philippines Technology and Service at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4120_6953_351845001659443843.png",
        linkedIn: "https://www.linkedin.com/in/vikash-sharma-933a8a19/",
      },
    ],
  },

  {
    _id: "5",
    time: "02:45 PM",
    date: "Sat 02:45 PM - 1  Session",
    bigDate: "28 Oct,02:45 PM - 03:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 381.png",
    tag: "Main Stage",
    heading:
      "Introduction to FIS LOBs (Lines of Business): Payments and Capital Markets",
    description:
      "Embark on a comprehensive exploration of the vital lines of business that underscore FIS's global impact. This panel discussion offers a panoramic view of FIS's core segments: Payments and Capital Markets. Engage with the distinguished FIS India leadership team, delve into the nuances of key product offerings, and map out their global footprints. Travel through the annals of FIS's growth and gaze into future product trajectories. For startups aspiring to thrive within the FIS ecosystem, this session will be instrumental in demystifying essential FIS terminologies and strategizing alignment.",
    tutorialLink: "https://www.youtube.com/embed/Q4BTaJkKb0s",
    speaker: [
      {
        _id: "1",
        speakerName: "Karthik Ranganathan",
        designation: "Development Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3044_6701_493902001696938476.png",
        linkedIn: "https://www.linkedin.com/in/karthikranganathan/",
      },
      {
        _id: "2",
        speakerName: "Kausik Chatterjee",
        designation:
          "Senior Director, Technology – Capital Markets at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3857_6442_195530001660827096.png",
        linkedIn: "https://www.linkedin.com/in/kausikchatterjee-insead/",
      },
      {
        _id: "3",
        speakerName: "Manoj Joshi",
        designation: "Senior Director at FIS",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4286_6502_404218001698425117.png",
        linkedIn: "https://www.linkedin.com/in/mkjoshi/",
      },
      {
        _id: "4",
        speakerName: "Pranshu Gupta",
        designation: "Sr Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3368_8468_996154001696946563.png",
        linkedIn: "https://www.linkedin.com/in/pranshugupta/",
      },
    ],
  },

  {
    _id: "6",
    time: "03:15 PM",
    date: "Sat 03:15 PM - 1  Session",
    bigDate: "28 Oct,03:15 PM - 04:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 210.png",
    tag: "FIS & Innovation",
    heading: "FIS strategic Innovation Programs & beyond",
    description:
      "Having discerned their place within FIS's vast architecture and explored the wealth of API integrations, startups are now poised to delve into the crux of collaborative innovation: FIS's strategic programs. This session unfolds the myriad avenues FIS has architected for bolstered partner engagements. Through enlightening panel discussions, participants will be introduced to the essence of FIS's Fintech advocacy, the dynamics of the accelerator program, and the vision behind ventures and alliance partner initiatives. Join us to navigate the pathways of partnership, synergistic innovation, and boundless growth within the FIS landscape.",
    tutorialLink: "https://www.youtube.com/embed/6-nHB-_IlVY",
    speaker: [
      {
        _id: "1",
        speakerName: "John Avery",
        designation:
          "Product Head, Capital Markets, Crypto & Digital As at FIS Globa",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3807_9250_032129001659446787.png",
        linkedIn: "https://www.linkedin.com/in/averyjohn/",
      },
      {
        _id: "2",
        speakerName: "Stephane Wyper",
        designation: "SVP, FIS Ventures at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1915_7533_902291001696940215.png",
        linkedIn: "https://www.linkedin.com/in/stephanewyper/",
      },
      {
        _id: "3",
        speakerName: "Tatyana Kratunova",
        designation: "FINANCIAL TECHNOLOGY EXECUTIVE at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2463_5323_428978001698214847.png",
        linkedIn: "https://www.linkedin.com/in/tatyanakratunova/",
      },
      {
        _id: "4",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
      {
        _id: "5",
        speakerName: "Manel Harrathi",
        designation: "Senior Director Strategic Innovation at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3744_6650_787976001696576755.png",
        linkedIn: "https://www.linkedin.com/in/manelharrathi/",
      },
      {
        _id: "6",
        speakerName: "Mukul Agrawal",
        designation: "",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2035_5275_334224001659445957.png",
        linkedIn: "https://www.linkedin.com/in/mukul-agrawal-26021b1/",
      },
    ],
  },

  {
    _id: "7",
    time: "04:15 PM",
    date: "Sat 04:15 PM - 1  Session",
    bigDate: "28 Oct,04:15 PM - 05:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 209.png",
    tag: "FIS & Innovation",
    heading: "FIS Developer Experience and Code Connect - Building on FIS APIs",
    description:
      "Venture deeper into the FIS ecosystem with a focus on the heart of innovation: APIs. Building upon your understanding of FIS's Lines of Business, this session zooms in on the FIS Developer Experience Programs, illuminating how each segment provides bespoke avenues for partners to enhance and create with FIS APIs. Central to this is the Code Connect Marketplace, an intuitive, self-service platform boasting a vast array of FIS APIs. Whether you're exploring Payments, Banking, Loyalty, Wealth, or Card Management, this hub offers an expansive and constantly evolving API catalog. Dive in to accelerate your innovation journey and refine your solutions in alignment with FIS's cutting-edge offerings.",
    tutorialLink: "https://www.youtube.com/embed/Z1ef0FeA3xY",
    speaker: [
      {
        _id: "1",
        speakerName: "Pravin Kumar",
        designation: "Director Development API Gateway Platform at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/6415e5b0-74ea-11ee-8fd5-2bef0f954af0_1698426054539.jpg",
        linkedIn: "https://www.linkedin.com/in/pravindeo/",
      },
      {
        _id: "2",
        speakerName: "Pawan P Kumar",
        designation: "Development Manager Senior at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4570_8285_175692001698220707.png",
        linkedIn: "https://www.linkedin.com/in/pawanbits/",
      },
      {
        _id: "3",
        speakerName: "Christopher Constantinou",
        designation: "Director, Digital Payments Capabilities at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4983_9000_819678001660916707.png",
        linkedIn: "https://www.linkedin.com/in/christopher-c-3268a66a/",
      },
      {
        _id: "4",
        speakerName: "Sachin Pawaskar",
        designation: "Director, Merchant Solutions Development at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1757_9333_191105001698219278.png",
        linkedIn: "https://www.linkedin.com/in/sachin-pawaskar/",
      },
      {
        _id: "5",
        speakerName: "Prashant Setia",
        designation: "Development Manager at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2955_6848_822761001660619747.png",
        linkedIn: "https://www.linkedin.com/in/prashantsetia/",
      },
      {
        _id: "6",
        speakerName: "Sivaselvam Sigamani",
        designation: "Sr. Business System Analyst at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/0e56f970-6bf3-11ee-a3be-ddba0a94df29_1697440215687.png",
        linkedIn: "https://www.linkedin.com/in/sivaselvam-sigamani-b6b67998/",
      },
      {
        _id: "7",
        speakerName: "Eric Guion",
        designation: "Product Manager - Strategic Alliances at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2580_7539_297343001698412904.png",
        linkedIn: "https://www.linkedin.com/in/eric-guion-20736415/",
      },
      {
        _id: "8",
        speakerName: "Karl Asha",
        designation: "Product Manager at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4506_5417_537713001659445019.png",
        linkedIn: "https://www.linkedin.com/in/karl-asha-958685/",
      },
    ],
  },

  {
    _id: "8",
    time: "05:15 PM",
    date: "Sat 05:15 PM - 1  Session",
    bigDate: "28 Oct,05:15 PM - 06:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 212.png",
    tag: "Data Innovation",
    heading: "Data Innovation",
    description:
      "In the intricate realm of finance, artificial intelligence (AI) emerges as a beacon of transformative potential. Today's financial entities harness AI to revolutionize facets ranging from monitoring, reporting, and regulatory compliance to wealth management, lending decisions, and fraud detection. This session delves deep into the groundbreaking strides made in Regtech, Risktech, ESG, and the expansive applications of AI in the Fintech universe. Join us to unravel the tapestry of data-driven innovations, comprehend the depth of current endeavors, and envision the future of AI-powered FinTech solutions.",
    tutorialLink: "https://www.youtube.com/embed/3Kg0ojzPmPE",
    speaker: [
      {
        _id: "1",
        speakerName: "Sivaselvam Sigamani",
        designation: "Sr. Business System Analyst at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/0e56f970-6bf3-11ee-a3be-ddba0a94df29_1697440215687.png",
        linkedIn: "https://www.linkedin.com/in/sivaselvam-sigamani-b6b67998/",
      },
      {
        _id: "2",
        speakerName: "Kiran Jain",
        designation: "VP, Strategic Innovation at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3173_9860_637946001698375338.png",
        linkedIn: "https://www.linkedin.com/in/kiranjain/",
      },
      {
        _id: "3",
        speakerName: "Ravi Dangeti",
        designation: "Sr. Director, IT Architecture at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3607_9593_246935001696577053.png",
        linkedIn: "https://www.linkedin.com/in/ravi-shankar-dangeti-6892322/",
      },
    ],
  },

  {
    _id: "9",
    time: "06:15 PM",
    date: "Sat 06:15 PM - 1  Session",
    bigDate: "28 Oct,06:15 PM - 07:15 PM",
    bannerImage: "./assets/images/Day_2/Frame 211.png",
    tag: "AI In Fintech",
    heading: "Generative AI & Next Fintech Disruption",
    description:
      "Dive into the revolutionary realm of Generative AI and its potential to redefine the fintech landscape. This session will illuminate how generative models are poised to usher in the next wave of fintech innovations, from creating synthetic financial datasets to envisioning entirely new financial products and services. Join us to explore the frontier of AI-driven disruptions and the future of fintech.",
    tutorialLink: "https://www.youtube.com/embed/hmrgyw-Kc4o",
    speaker: [
      {
        _id: "1",
        speakerName: "Tom Weaver",
        designation:
          "SVP, Leveraged Infrastructure - Corporate Systems at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1257_7684_413433001697267455.png",
        linkedIn: "https://www.linkedin.com/in/tom-weaver-ba98a7170/",
      },
      {
        _id: "2",
        speakerName: "Richard Peterson",
        designation: "Sr. Director, Enterprise Strategy at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3689_9667_724282001695897516.png",
        linkedIn: "https://www.linkedin.com/in/richardcpetersonny/",
      },
      {
        _id: "3",
        speakerName: "Venkat Namachivayam",
        designation: "Director Development at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4542_5606_516621001696939118.png",
        linkedIn: "https://www.linkedin.com/in/venkat1999/",
      },
      {
        _id: "4",
        speakerName: "Gary Duma",
        designation:
          "Data Science Sr Manager, Leveraged Infrastructure at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2955_5154_132485001696943128.png",
        linkedIn: "https://www.linkedin.com/in/gary-duma-1754824b/",
      },
      {
        _id: "5",
        speakerName: "Ravi Dangeti",
        designation: "Sr. Director, IT Architecture at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3607_9593_246935001696577053.png",
        linkedIn: "https://www.linkedin.com/in/ravi-shankar-dangeti-6892322/",
      },
    ],
  },

  {
    _id: "10",
    time: "07:15 PM",
    date: "Sat 07:15 PM - 1  Session",
    bigDate: "228 Oct,07:15 PM - 07:45 PM",
    bannerImage: "./assets/images/Day_2/Frame 289 (1).png",
    tag: "Embedded Finance & B2B Fintech Future In APAC",
    heading: "Card & Money Movement: The Pulse of Modern Finance",
    description:
      "In today's digital age, the dynamics of card transactions and money movements are pivotal in shaping the financial landscape. This session delves into the intricacies of card-based systems and the evolving methodologies of money transfers. With insights from FIS experts, explore the challenges, innovations, and opportunities in these sectors. Discover the technological advancements driving seamless transactions, the security protocols ensuring safety, and the trends that are setting the pace for tomorrow's financial world. Engage with us to understand the heartbeat of modern financial interactions.",
    tutorialLink: "https://www.youtube.com/embed/Tx4Z86cpfI0",
    speaker: [
      {
        _id: "1",
        speakerName: "Chris Como",
        designation:
          "GM and Head of Product, Cards and Money Movement at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1897_6472_693973001696944983.png",
        linkedIn: "https://www.linkedin.com/in/chris-como-716811/",
      },
      {
        _id: "2",
        speakerName: "John Stuckey",
        designation: "Head of Product Management at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2697_7027_618521001697170476.png",
        linkedIn: "https://www.linkedin.com/in/johntstuckey/",
      },
      {
        _id: "3",
        speakerName: "Pradeesh TK",
        designation: "Associate Director - Business Analysis at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3394_9500_369737001696916197.png",
        linkedIn: "https://www.linkedin.com/in/pradeeshtk/",
      },
    ],
  },

  {
    _id: "12",
    time: "10:30 AM",
    date: "Sun 10:30 AM - 1  Session",
    bigDate: "29 Oct,10:30 AM - 11:25 AM",
    bannerImage: "./assets/images/Day_2_1/Frame 383.png",
    tag: "Main Stage",
    heading: "Welcome, Keynote, & Introduction",
    description:
      "Unveiling Fintech's Next Frontier Step into the realm of fintech mastery with FIS leaders, Venu Madhav and Prashant Mane, in an exclusive event that navigates through innovation, strategy, and the digital metamorphosis of fintech ecosystem. As trailblazers of the industry, they unravel the synergies between startups and global corporations, highlighting the revolutionary impact of cohesive ecosystems. This session is a crucible of insights, drawing from profound expertise and futuristic thinking, set to redefine your understanding of fintech's evolving landscape. Engage with us in this thought-provoking exchange that promises to stretch the boundaries of traditional finance!",
    tutorialLink: "https://www.youtube.com/embed/CmZD7NOBKEw",
    speaker: [
      {
        _id: "1",
        speakerName: "Karthik Ranganathan",
        designation: "Development Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3044_6701_493902001696938476.png",
        linkedIn: "https://www.linkedin.com/in/karthikranganathan/",
      },
      {
        _id: "2",
        speakerName: "Venu Madhav",
        designation: "Senior Vice President, IT Group Executive at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3851_8359_084923001695896725.png",
        linkedIn: "https://www.linkedin.com/in/venu-madhav-1684874/",
      },
      {
        _id: "3",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
    ],
  },

  {
    _id: "13",
    time: "11:30 AM",
    date: "Sun 11:30 AM - 2  Sessions",
    bigDate: "29 Oct,11:30 AM - 12:00 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 298.png",
    tag: "Main Stage",
    heading: "Key Note: Fintech Investment",
    description:
      'Embark on an exploratory journey from the global fintech landscape down to the innovative fervor of APAC and India with Pranav, a seasoned board member and advisor to numerous burgeoning companies including Licious and Darwinbox. In this keynote, discover the narrative of "India - Startup Nation" as it pivots to become the epicenter of fintech innovation. Navigating from global tendencies to India’s burgeoning opportunities, Pranav will elucidate how the Indian startup ecosystem isn’t just thriving, but is on a trajectory to sculpt a 10 Trillion USD economy, marrying technology, and financial ingenuity in the sprawling theater of tech-driven value capture.',
    tutorialLink: "https://www.youtube.com/embed/WLZA0bZ6h2g",
    speaker: [
      {
        _id: "1",
        speakerName: "Pranav Pai",
        designation: "Found Partner at 3One4 Capital",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1369_5526_485972001695629816.png",
        linkedIn: "https://www.linkedin.com/in/pranavpai/",
      },
      {
        _id: "2",
        speakerName: "Pranshu Gupta",
        designation: "Sr Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3368_8468_996154001696946563.png",
        linkedIn: "https://www.linkedin.com/in/pranshugupta/",
      },
    ],
  },

  {
    _id: "14",
    time: "11:30 AM",
    date: "Sun 11:30 AM - 2  Sessions",
    bigDate: "29 Oct,11:30 AM - 12:00 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 299.png",
    tag: "Main Stage",
    heading: "Key Note: Digital Public Infrastructure",
    description:
      "Delve into the intricate narrative of 'Digital Public Infrastructure' with Abhay Prasad, the former chairman of the Federal Bank and a significant influencer in India's banking evolution. After an illustrious 27-year tenure at the RBI and pivotal roles, including the MD and CEO of NPCI, Mr Hota's experiences offer unparalleled insights. As digital transformation accelerates in India, APAC, and on a global scale, his perspective will elucidate the development and implications of digital infrastructures. Gain understanding into how public digital platforms are revolutionizing transactions, broadening financial inclusivity, and influencing the overarching economic discourse across regions.",
    tutorialLink: "https://www.youtube.com/embed/xmnzVJFEM_U",
    speaker: [
      {
        _id: "1",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
      {
        _id: "2",
        speakerName: "Abhaya Hota",
        designation: "Formar CEO & MD at NPCI",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4618_8152_984724001697541952.png",
        linkedIn: "https://www.linkedin.com/in/abhaya-hota-0a4956100/",
      },
    ],
  },

  {
    _id: "15",
    time: "12:00 PM",
    date: "Sun 12:00 PM - 2  Sessions",
    bigDate: "29 Oct,12:00 PM - 01:00 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 205.png",
    tag: "Main Stage",
    heading: "Fintech Investment Landscape - Venture Capital view",
    description:
      "Dive deep into the 'Fintech Investment Landscape' from the venture capital perspective with eminent industry stalwarts. Anuradha Ramachandran, with her vast experience from TVS Capital Funds and Flourish Ventures, teams up with Manv, a seasoned professional who has spent over a decade honing skills in venture capital, consulting, and corporate development, especially within APAC's fintech, insurtech, and health tech domains. Joining them is Edison, the linchpin of SeedIn Technology in the Philippines, bringing in over 17 years of expertise in information management projects. Together, they'll shed light on the dynamic fintech investment horizon, underpinned by the transformative spirit of InnovateIN48 Partner Edition, aiming to foster a robust ecosystem of innovation and collaboration.",
    tutorialLink: "https://www.youtube.com/embed/Qf3oTdWd7yE",
    speaker: [
      {
        _id: "1",
        speakerName: "Edison Tsai",
        designation: "Partnerships Committee Chair at Fintech Alliance.ph",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4045_5461_916948001695814513.png",
        linkedIn: "https://www.linkedin.com/in/tsaiedison/",
      },
      {
        _id: "2",
        speakerName: "Manav Narang",
        designation:
          "Director & Head of APAC, Financial services & heal at Plug and Play Tech Center, Singapore",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1992_5892_276485001695813375.png",
        linkedIn: "https://www.linkedin.com/in/manav-narang/",
      },
      {
        _id: "3",
        speakerName: "Anuradha Ramachandran",
        designation: "Managing Partner at TVS Capital",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4269_5771_064164001697120371.png",
        linkedIn: "https://www.linkedin.com/in/anuradharamachandran/",
      },
      {
        _id: "4",
        speakerName: "Pranshu Gupta",
        designation: "Sr Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3368_8468_996154001696946563.png",
        linkedIn: "https://www.linkedin.com/in/pranshugupta/",
      },
    ],
  },

  {
    _id: "16",
    time: "12:00 PM",
    date: "Sun 12:00 PM - 2  Sessions",
    bigDate: "29 Oct,12:00 PM - 01:00 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 290.png",
    tag: "Main Stage",
    heading: "Reimagining Digital Commerce with ONDC & India Stacks",
    description:
      "Delve into the transformative world of digital commerce with ONDC and India Stack. Learn from Nitin Sharma's endeavors to empower budding entrepreneurs on the ONDC platform with substantial seed capital and a unique venture ecosystem. Engage with Sanjay Jain's expertise, who has significantly contributed to pivotal innovations like Aadhaar, UPI, and India Stack. This session intertwines innovation, investment, and technology, offering a blueprint for the evolution of India's digital commerce landscape. Don't miss this deep dive into the convergence of technology and business.",
    tutorialLink: "https://www.youtube.com/embed/FR3KncRGFfs",
    speaker: [
      {
        _id: "1",
        speakerName: "Nitin Sharmai",
        designation: "Cofounder & General Partner at Antler",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4349_7508_387312001697120536.png",
        linkedIn: "https://www.linkedin.com/in/nitinsharma15/",
      },
      {
        _id: "2",
        speakerName: "Sanjay Jain",
        designation: "CIO at CIIIE",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2034_8503_147465001697120581.png",
        linkedIn: "https://www.linkedin.com/in/snjyjn/",
      },
      {
        _id: "3",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
    ],
  },

  {
    _id: "17",
    time: "01:00 PM",
    date: "Sun 01:00 PM - 1  Session",
    bigDate: "29 Oct,01:00 PM - 02:00 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 257.png",
    tag: "Main Stage",
    heading: "Fintech Investment Landscape - Corporate Venture Capital view",
    description:
      'Explore the intricate realm of the "Fintech Investment Landscape" through the lens of Corporate Venture Capital (CVC) with seasoned industry leaders. Santosh Patel, an international finance expert with unparalleled insights into global trading systems joins hands with Vishnu Acharya who shares his vast expertise in fintech M&As and investments across prominent platforms. Together, they illuminate the workings of the CVC ecosystem, offering invaluable insights on how startups can effectively navigate and engage with this dynamic space, all set against the backdrop of the spirit of innovation and collaboration inherent to the InnovateIN48 Partner Edition.',
    tutorialLink: "https://www.youtube.com/embed/dn-k2RjJNoQ",
    speaker: [
      {
        _id: "1",
        speakerName: "Pranshu Gupta",
        designation: "Sr Director at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3368_8468_996154001696946563.png",
        linkedIn: "https://www.linkedin.com/in/pranshugupta/",
      },
      {
        _id: "2",
        speakerName: "Santosh Patel",
        designation:
          "Leading Fintech Engagements & Partnerships at SC Ventures",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3570_7038_935798001695814316.png",
        linkedIn: "https://www.linkedin.com/in/santoshpatel/",
      },
      {
        _id: "3",
        speakerName: "Vishnu Acharya",
        designation: "Head of Corporate Development & Investments at Razorpay",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2623_9962_899975001696400664.png",
        linkedIn: "https://www.linkedin.com/in/vishnu-acharya-322a78100/",
      },
    ],
  },

  {
    _id: "19",
    time: "02:30 PM",
    date: "Sun 02:30 PM - 1  Session",
    bigDate: "29 Oct,02:30 PM - 03:30 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 300.png",
    tag: "Data Innovation",
    heading: "Data Innovation & Regulation",
    description:
      "Explore the dynamic realm of data innovation and regulation with renowned industry leaders. Join Mayur Datar from Flipkart as he shares insights into shaping India's e-commerce landscape through advanced data science.  Round off with Gopikrishnan Varadarajula's deep dive into product development intricacies in IT, emphasizing cloud, enterprise architectures, and data security. Dive deep with Surabhya Rao as a moderator with her expertise in the world of fintech.This session promises a holistic view of the data-driven innovations transforming businesses globally.",
    tutorialLink: "https://www.youtube.com/embed/lMaSOYidvbM",
    speaker: [
      {
        _id: "1",
        speakerName: "Mayur Datar",
        designation: "Chief Data Scientist at Flipkart",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4116_8956_439562001695224829.png",
        linkedIn: "https://www.linkedin.com/in/mayur-datar-b0a65018/",
      },
      {
        _id: "2",
        speakerName: "Surabhya Rao",
        designation: "Associate Director, Development at FIS",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4063_6071_563487001698409178.png",
        linkedIn: "https://www.linkedin.com/in/surabhya-rao-8b819853/",
      },
      {
        _id: "3",
        speakerName: "Gopikrishnan Varadarajulu",
        designation:
          "Solutions Architect, Emerging Technologies at IBM Analytics",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2024_7662_078108001697120865.png",
        linkedIn: "https://www.linkedin.com/in/gopi-varadarajulu/",
      },
    ],
  },

  {
    _id: "20",
    time: "03:30 PM",
    date: "Sun 03:30 PM - 1  Session",
    bigDate: "29 Oct,03:30 PM - 04:30 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 256.png",
    tag: "Digital Assets",
    heading: "Digital Assets 2.0: How Digital Assets Impact Financial Markets",
    description:
      "Digital assets have been on the rise. Tokenization and blockchain technology can potentially change the finance world in an unprecedented way. This panel discussion will revolve around digital assets' impact on financial markets. We will have a look at the key distinctions between “classic” assets and digital assets and further enlighten the role of custody in this context. Moreover, we will discuss regulated financial service providers and the chances and challenges they face regarding these novelties. As digital assets are a relatively young phenomenon, there are a variety of inquiries regarding their impact on markets in general and specifically the finance sector.",
    tutorialLink: "https://www.youtube.com/embed/IjwIBCpB-SQ",
    speaker: [
      {
        _id: "1",
        speakerName: "Sai Prasad Raut",
        designation: "Strategic Advisor- Crypto and Web 3 at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4692_7564_716904001659447211.png",
        linkedIn: "https://www.linkedin.com/in/saiprasad-raut-857a154/",
      },
      {
        _id: "2",
        speakerName: "Parth Chaturvedi",
        designation: "Investments Lead at Coinswitch",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1954_6052_291827001696400049.png",
        linkedIn: "https://www.linkedin.com/in/parth-chaturvedi-a672165a/",
      },
      {
        _id: "3",
        speakerName: "Ed Marquez",
        designation: "Developer Relations at Hedera",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1726_8323_051226001697007500.png",
        linkedIn: "https://www.linkedin.com/in/ed-marquez/",
      },
      {
        _id: "4",
        speakerName: "Ravikant Agrawal",
        designation: "Director of growth at Polygon Labs",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2599_5690_760765001659499367.png",
        linkedIn: "https://www.linkedin.com/in/ravikantagrawal/",
      },
      {
        _id: "5",
        speakerName: "Talha Khan",
        designation: "Product Manager at IBM",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3700_8236_680361001697120332.png",
        linkedIn: "https://www.linkedin.com/in/talha--khan/",
      },
    ],
  },

  {
    _id: "26",
    time: "05:30 PM",
    date: "Sun 05:30 PM - 1  Session",
    bigDate: "29 Oct,05:30 PM - 06:30 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 204.png",
    tag: "Main Stage",
    heading: "Fintech APAC Landscape and Trends",
    description:
      "Navigating the intricacies of fintech ecosystems across APAC, this forum gathers an elite group of the region's most influential leaders. Simone Joyce, a trailblazer in Australian fintech with Paypa Plane, will provide her deep insights into policy and regulatory shifts. Lito Villanueva, pivotal in the Philippines' digital transformation, signifies the immense fintech-initiated transactional volume in the country. Niki Luhur, an advocate for financial inclusion in Indonesia, will discuss the transformative potential of open platforms and AI's imminent impact. Representing India, Naveen Surya, a renowned fintech expert, underscores the magnitude and significance of the Global Fintech Fest. This distinguished panel promises a profound exploration into global, APAC, and country-specific fintech trends, fostering a thorough grasp of the sector's evolving dynamics.",
    tutorialLink: "https://www.youtube.com/embed/-NsRYr9J1hE?rel=0",
    speaker: [
      {
        _id: "1",
        speakerName: "Simone Joyce",
        designation: '"Chairperson & CEO" at "Fintech Australia & Paypa Plane"',
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3803_5174_099030001695223598.png",
        linkedIn: "https://www.linkedin.com/in/simone-joyce-42025b72/",
      },
      {
        _id: "2",
        speakerName: "Lito Villanueva",
        designation:
          "Chairman of the Board at Alliance of Digital Finance Associations",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3685_9953_475887001696399716.png",
        linkedIn: "https://www.linkedin.com/in/litovillanueva/",
      },
      {
        _id: "3",
        speakerName: "Naveen Surya",
        designation:
          "Founder, Advisory Board Member at Global Fintech Festival",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4066_5484_491770001695223807.png",
        linkedIn: "https://www.linkedin.com/in/naveensurya/",
      },
      {
        _id: "4",
        speakerName: "Niki Luhur",
        designation:
          "Co-founder and Board of Supervisors at Indonesian Fintech Association (AFTECH)",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4444_9570_776646001697120630.png",
        linkedIn: "https://www.linkedin.com/in/niki-luhur-1053481/",
      },
      {
        _id: "5",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
    ],
  },

  {
    _id: "28",
    time: "07:30 PM",
    date: "Sun 07:30 PM - 1  Session",
    bigDate: "29 Oct,07:30 PM - 08:30 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 301.png",
    tag: "AI In Fintech",
    heading: "AI & Next Fintech Disruptions",
    description:
      "Embark on a journey through AI and its transformative potential in fintech. Santanu Bhattacharya, with a legacy from NatWest Group to NASA and MIT, illuminates the path of technological advancements. Accompanied by professionals like Peter Goth, deeply entrenched in the financial services domain, they shed light on the symbiosis of Data & AI, Automation, and Security in the sector. Denzil Menezes, with his profound experience, will dive into the prevailing AI trends in the industry, offering a broader perspective devoid of product-centric discussions as Harel Kodesh shares his extensive insights from an investor's point of view. This session is a confluence of insights, experiences, and a peek into the future of fintech shaped by AI.",
    tutorialLink: "https://www.youtube.com/embed/VV8yfPcwXv0?rel=0",
    speaker: [
      {
        _id: "1",
        speakerName: "Prashant Mane",
        designation: "Director - Client Relations at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png",
        linkedIn: "https://www.linkedin.com/in/prashantmane/",
      },
      {
        _id: "2",
        speakerName: "Harel Kodesh",
        designation: "Investor at Privya.ai",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3715_7274_780841001698252305.png",
        linkedIn: "https://www.linkedin.com/in/harel-kodesh/",
      },
      {
        _id: "3",
        speakerName: "Peter Goth",
        designation:
          "Managing Director, Banking & Business Applications at Microsoft",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3106_7831_777047001697620713.png",
        linkedIn: "https://www.linkedin.com/in/petergoth/",
      },
      {
        _id: "4",
        speakerName: "Santanu Bhattacharya",
        designation: "Chief Technologists at NatWest Group",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4785_9932_542959001697037982.png",
        linkedIn: "https://www.linkedin.com/in/santanub/",
      },
      {
        _id: "5",
        speakerName: "Denzil Menezes",
        designation: "Principal Architect at IBM",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2169_7961_257419001697120016.png",
        linkedIn: "https://www.linkedin.com/in/denzil-menezes-a4277729/",
      },
    ],
  },

  {
    _id: "29",
    time: "08:30 PM",
    date: "Sun 08:30 PM - 1  Session",
    bigDate: "29 Oct,08:30 PM - 09:15 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 354 (1).png",
    tag: "Embedded Finance & B2B Fintech Future In APAC",
    heading: "Embedded Finance & B2B Fintech Future- Strategic View",
    description:
      "Dive deep into the evolving landscape of Embedded Finance and B2B Fintech in the APAC region. Huw Davies, with his profound experience from HSBC to the pioneering open banking initiatives in the UK, elucidates the shifting paradigms of global payments. Paolo Sironi, combining his quantitative expertise and thought leadership at IBM, provides a perspective on the digital transformation shaping banking and finance, highlighting the significance of platform economies. This session promises a comprehensive overview of fintech's future trajectory in APAC.",
    tutorialLink: "https://www.youtube.com/embed/wk15Dpxk7rY",
    speaker: [
      {
        _id: "1",
        speakerName: "Huw Davies",
        designation: "Co-founder & CEO at Ozone API",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/4977_6258_911058001696508063.png",
        linkedIn: "https://www.linkedin.com/in/huwwilliamdavies/",
      },
      {
        _id: "2",
        speakerName: "Paolo Sironi",
        designation:
          "Global Research Leader in Banking at IBM Institute for Business Value",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/1181_8775_164379001697120903.png",
        linkedIn: "https://www.linkedin.com/in/thepsironi/",
      },
      {
        _id: "3",
        speakerName: "Pradeesh TK",
        designation: "Associate Director - Business Analysis at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3394_9500_369737001696916197.png",
        linkedIn: "https://www.linkedin.com/in/pradeeshtk/",
      },
    ],
  },

  {
    _id: "30",
    time: "09:30 PM",
    date: "Sun 09:30 PM - 1  Session",
    bigDate: "29 Oct,09:30 PM - 10:15 PM",
    bannerImage: "./assets/images/Day_2_1/Frame 353 (1).png",
    tag: "Embedded Finance & B2B Fintech Future In APAC",
    heading: "Embedded Finance & B2B Fintech Future- Tech View",
    description:
      "Dive deep into the evolving landscape of Embedded Finance and B2B Fintech in the APAC region. Varun Sridhar, at the helm of Paytm Money, shares insights from his journey in digital wealth management, drawing parallels from his past roles in international banking. Tyler Pichach, Exec. Director, being the Worldwide FSI Business Strategy at Microsoft, sheds light as he continues to lead Microsoft's efforts in revolutionizing the financial services industry. This session promises a comprehensive overview of fintech's future trajectory in APAC.",
    tutorialLink: "https://www.youtube.com/embed/OPb-pvi2LtQ?rel=0",
    speaker: [
      {
        _id: "1",
        speakerName: "Varun Sridhar",
        designation: "CEO at PayTM Money",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3024_9874_527064001696399912.png",
        linkedIn: "https://www.linkedin.com/in/varun-sridhar-639146a/",
      },
      {
        _id: "2",
        speakerName: "Tyler Pichach",
        designation:
          "Exec. Director, Worldwide FSI Business Strategy at Microsoft",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/2403_9348_001622001697123256.png",
        linkedIn: "https://www.linkedin.com/in/tyler-pichach/",
      },
      {
        _id: "3",
        speakerName: "Pradeesh TK",
        designation: "Associate Director - Business Analysis at FIS Global",
        imageURL:
          "https://cdn.hubilo.com/speaker/3406161/150/3394_9500_369737001696916197.png",
        linkedIn: "https://www.linkedin.com/in/pradeeshtk/",
      },
    ],
  },
];

export default info;
