import React from 'react'
import ContactForm from '../../Components/ContactForm/ContactForm'
import Footer from '../../Components/Footer/Footer'
import CustomeNavbar from '../../Components/Navbar/CustomeNavbar'

function ContactUsScreen() {
     return (
          <>
               <CustomeNavbar />
               <ContactForm />
               <Footer />
          </>
     )
}

export default ContactUsScreen