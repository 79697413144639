import React from 'react'
import Footer from '../../Components/Footer/Footer'
import CustomeNavbar from '../../Components/Navbar/CustomeNavbar'
import ThanksText from '../../Components/ThanksText/ThanksText'

function ThanksScreen() {
     return (
          <>
               <CustomeNavbar />
               <ThanksText />
               <Footer />
          </>
     )
}

export default ThanksScreen