import React, { useState } from 'react'
import styles from "./RoundWinners.module.css"



function RoundWinners() {
     const [showIcon, setShowIcon] = useState(true);
     const [showIcon1, setShowIcon1] = useState(true);
     const [showIcon2, setShowIcon2] = useState(true);

     const buttonIconShow = () => {
          setShowIcon(prevCheck => !prevCheck);
          console.log(showIcon);
     }

     const buttonIconShow1 = () => {
          setShowIcon1(prevCheck => !prevCheck);
          console.log(showIcon);
     }

     const buttonIconShow2 = () => {
          setShowIcon2(prevCheck => !prevCheck);
          console.log(showIcon);
     }

     return (
          <>

               <div id='roundWinner' className={`container-fluid ${styles.round__Section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.round__Section__inner}`}>
                              <div className={`container-fluid ${styles.event_section}`}>
                                   <div className="row">
                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                                             <h1>2022 FINAL ROUND WINNERS</h1>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                                             <hr className={styles.small__hr__color}></hr>
                                        </div>

                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                                             <p>
                                                  FIS InnovateIN48 Partner Edition saw broad-based participation from high-potential fintechs and techfins.

                                                  In 2022,      <span className={styles.break}> <br></br></span> nine startups were selected across three themes based on solution innovation and co-creation ideation.
                                             </p>
                                        </div>


                                   </div>
                              </div>
                         </div>
                    </div>
                    <hr></hr>
               </div>




               <div className={styles.twentyhr__div} style={{ margin: '0%', padding: '0%' }}>
                    <hr className={styles.twentyhr}></hr>
               </div>


               <br></br> <br></br>
               <div className={`containes ${styles.accordian__div}`}>
                    <div className='sd'>
                         <div class="panel-group" id="accordionOne" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                   <div class="panel-heading" role="tab" id="headingOne">


                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" onClick={buttonIconShow}>
                                             <div style={{ display: 'flex' }}>
                                                  <div className={styles.square} data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                       <img src='./assets/images/DI.png' alt='' />
                                                  </div>
                                                  <div className={styles.accordian__title}>
                                                       <h5 data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
                                                            DATA INNOVATION
                                                       </h5>
                                                  </div>

                                             </div>

                                             <div>
                                                  {
                                                       showIcon ?


                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className={`fa-sharp fa-solid fa-plus ${styles.sharp}`}></i> :
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className={`fa-sharp fa-solid fa-minus ${styles.sharp}`}></i>
                                                  }
                                             </div>
                                        </div>


                                   </div>
                                   <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                        <div className={`accordion-body ${styles.accordion_body}`}>
                                             <h3>Finarkein</h3>
                                             <p>
                                                  OpenData OS helps to move from siloed datasets to open access and from corporate ownership to end-user ownership. Solution unlocks economic, societal and governance benefits across sectors.
                                             </p>

                                             <br></br>

                                             <h3>Yuva Pay</h3>
                                             <p>
                                                  Yuva Pay is the neobank for the unbanked and underserved populations of the world. It is a virtual bank that fosters financial inclusion at the grassroots level and works even without the internet.
                                             </p>

                                             <br></br>

                                             <h3>Tathya Earth</h3>
                                             <p>
                                                  Tathya Earth is building a platform to provide near real-time datasets and insights on commodities  supply chain (starting with metals and coal) using satellite data.
                                             </p>
                                        </div>


                                   </div>
                              </div>

                         </div>

                    </div>
               </div>

               <br></br>  <br></br>


               <div className={styles.twentyhr__div} style={{ margin: '0%', padding: '0%' }}>
                    <hr className={styles.twentyhr}></hr>
               </div>


               <br></br> <br></br>
               <div className={`containes ${styles.accordian__div}`}>
                    <div className='sd'>
                         <div class="panel-group" id="accordionThree" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                   <div class="panel-heading" role="tab" id="headingThree">


                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={buttonIconShow1}>
                                             <div style={{ display: 'flex' }}>
                                                  <div className={styles.square} data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                       <img src='./assets/images/AIF.png' alt='' />
                                                  </div>
                                                  <div className={styles.accordian__title}>
                                                       <h5 data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                                                            AI IN FINTECH
                                                       </h5>
                                                  </div>

                                             </div>

                                             <div>
                                                  {
                                                       showIcon1 ?


                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" className={`fa-sharp fa-solid fa-plus ${styles.sharp}`}></i> :
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" className={`fa-sharp fa-solid fa-minus ${styles.sharp}`}></i>
                                                  }
                                             </div>
                                        </div>


                                   </div>
                                   <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                        <div className={`accordion-body ${styles.accordion_body}`}>
                                             <h3>Intellewings</h3>
                                             <p>
                                                  IntelleWings, leveraging its cutting technology and proprietary algorithm, has built a screening database that checks all patterns in Money Laundering, Terrorism, Drug Trafficking, and Fraud from global sources.
                                             </p>

                                             <br></br>

                                             <h3>Oriserv</h3>
                                             <p>
                                                  Customer-centric end-to-end enterprise conversational AI-powered chatbots to automate the customer journey through its connected, multilingual, omnichannel, self-service platform.
                                             </p>

                                             <br></br>

                                             <h3>EdgeNeural</h3>
                                             <p>
                                                  EdgeNeural platform is a modular fully-integrated workflow to easily train, optimize, deploy and manage Edge AI neural networks.
                                             </p>
                                        </div>


                                   </div>
                              </div>

                         </div>

                    </div>
               </div>

               <br></br>  <br></br>

               <div className={styles.twentyhr__div} style={{ margin: '0%', padding: '0%' }}>
                    <hr className={styles.twentyhr}></hr>
               </div>
               <br></br> <br></br>
               <div className={`containes ${styles.accordian__div}`}>
                    <div className='sd'>

                         <div class="panel-group" id="accordionTwo" role="tablist" aria-multiselectable="true">
                              <div class="panel panel-default">
                                   <div class="panel-heading" role="tab" id="headingTwo">


                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={buttonIconShow2}>
                                             <div style={{ display: 'flex' }}>
                                                  <div className={styles.square} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                       <img src='./assets/images/DA.png' alt='' />
                                                  </div>
                                                  <div className={styles.accordian__title}>
                                                       <h5 data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                                                            DIGITAL ASSETS
                                                       </h5>
                                                  </div>

                                             </div>

                                             <div>
                                                  {
                                                       showIcon2 ?
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className={`fa-sharp fa-solid fa-plus ${styles.sharp}`}></i> :
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className={`fa-sharp fa-solid fa-minus ${styles.sharp}`}></i>
                                                  }
                                             </div>
                                        </div>


                                   </div>
                                   <div id="collapseTwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
                                        <div className={`accordion-body ${styles.accordion_body}`}>
                                             <h3>Payscript </h3>
                                             <p>
                                                  Provides a cryptocurrency payment platform that allows consumers to buy, sell swap and spend crypto through an easily accessible and secure wallet.
                                             </p>

                                             <br></br>

                                             <h3>Koinearth</h3>
                                             <p>
                                                  Enterprise-grade solution for digital supply chains. By enabling secure data sharing in B2B ecosystems, the solution enables ESG compliance, supply chain finance, and operations efficiency.
                                             </p>

                                             <br></br>

                                             <h3>LegitDoc</h3>
                                             <p>
                                                  Using LegitDoc any document issuers can issue tamper-proof digital documents to their stakeholders that be verified from anywhere around the world, within a matter of few seconds.
                                             </p>
                                        </div>


                                   </div>
                              </div>

                         </div>
                    </div>

                    <br></br>  <br></br> <br></br>
               </div>

          </>
     )
}

export default RoundWinners