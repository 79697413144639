import React from 'react'
import styles from "./PartnerThanks.module.css"


function PartnerThanks() {
     return (
          <>
               <div className={`container-fluid ${styles.Gradiant__Section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.Gradiant__Section__inner}`}>
                              <div className={`container-fluid ${styles.event_section}`}>
                                   <div className="row">
                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                                             <h1>SPECIAL THANKS TO OUR EVENT PARTNERS
                                             </h1>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                                             <hr className={styles.small__hr__color}></hr>
                                        </div>





                                        <div className={`container-fluid ${styles.event_section}`}>
                                             <div className="row" style={{ justifyContent: 'center' }}>
                                                  <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.event_section_inner}`}>
                                                       <img className={styles.event_section_inner__image1} src="./assets/images/aws.png" alt="das" />
                                                  </div>
                                                  <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.event_section_inner}`}>
                                                       <img className={styles.event_section_inner__image2} src="./assets/images/Hedera-logo.png" alt="das" />
                                                  </div>
                                                  <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.event_section_inner}`}>
                                                       <img className={styles.event_section_inner__image3} src="./assets/images/sds.png" alt="das" />
                                                  </div>
                                                  <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.event_section_inner}`}>
                                                       <img className={styles.event_section_inner__image4} src="./assets/images/ifsca.png" alt="das" />
                                                  </div>
                                                  <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.event_section_inner}`}>
                                                       <img className={styles.event_section_inner__image5} src="./assets/images/impact.png" alt="das" />
                                                  </div>

                                             </div>
                                        </div>


                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default PartnerThanks