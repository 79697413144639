import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from "./ContactForm.module.css"
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Countries from "../../Data/Countries"
import States from "../../Data/states"

function ContactForm() {
     const navigate = useNavigate();
     const [show, setShow] = useState(false);
     const handlClose = () => {
          setShow(false);
     }
     const handlShow = () => {
          setShow(true);
     }

     const form = useRef();
     const sendEmail = (e) => {
          e.preventDefault();
          emailjs.sendForm('service_60mq40r', 'template_5cirzkd', form.current, '5tdTNmOsMYAIuvog6')
               .then((result) => {
                    console.log(result.text);
                    console.log("Message Sent Successfully");
                    navigate("/thankyou")
               }, (error) => {
                    console.log(error.text);
               });
     };



     const [firstname, setFirstname] = useState('');
     const [lastname, setLastname] = useState('');
     const [comapny, setComapny] = useState('');
     const [email, setEmail] = useState('');
     const [number, setNumber] = useState('');
     const [country, setCountry] = useState('');
     const [state, setState] = useState('');
     const [city, setCity] = useState('');
     const [website, setWebsite] = useState('');
     const [business, setBusiness] = useState('');
     const [statement, setStatement] = useState('');
     const [linkedIn, setLinkedin] = useState('');
     const [checkYes, setCheckYes] = useState('');
     const [checkNo, setCheckNo] = useState('');
     const [open, setOpen] = React.useState(false);
     const handleClickOPenSnackBar = () => {
          setOpen(true);
     };
     const handleCloseSnackBar = (event, reason) => {
          if (reason === 'clickaway') {
               return;
          }
          setOpen(false);
     };
     function SubmitButton() {
          if (firstname && lastname && comapny && email && number && country && state && city && website && business && statement && linkedIn && checkYes) {
               return <button className={styles.submit_button} onClick={sendEmail} type="submit">Submit</button>
          } else {
               return <button className={styles.submit_button} type="button"
                    onClick={handleClickOPenSnackBar}>Submit</button>
          };
     };

     return (
          <>

               <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar
                         open={open}
                         autoHideDuration={4000}
                         onClose={handleCloseSnackBar}
                         anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                         }}
                    >
                         <MuiAlert style={{
                              backgroundColor: 'red',
                              color: 'white'
                         }} onClose={handleCloseSnackBar}
                              lg={{ width: '100%' }}
                              severity="error">Kindly fill all the necessary fields! (*)</MuiAlert>
                    </Snackbar>
               </Stack>
               <div className={`container-fluid ${styles.PartnerEdition_section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                              <h1>Contact Us</h1>
                         </div>
                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                              <hr className={styles.small__hr__color}></hr>
                         </div>
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                              <p>
                                   Thank you for your interest in FIS InnovateIN48 Partner Edition, If you would like to learn more or  <span className={styles.break}> <br></br></span>participate in our future events and programs, please fill out the form below and we will be in touch.
                              </p>
                         </div>
                    </div>
               </div>


               <form className={styles.formContainer} ref={form} onSubmit={sendEmail} >
                    <div className={`container ${styles.contactUs_section}`}>
                         <div className="row">
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             First Name
                                        </p>
                                   </div>
                                   <input value={firstname} onChange={e => setFirstname(e.target.value)} required type='text' name="firstName"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Last Name
                                        </p></div>
                                   <input value={lastname} onChange={e => setLastname(e.target.value)} required type='text' name="lastName"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Company Name
                                        </p></div>
                                   <input value={comapny} onChange={e => setComapny(e.target.value)} required type='text' name="companyName"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Email
                                        </p></div>
                                   <input value={email} onChange={e => setEmail(e.target.value)} required type='email' name="email"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Contact Number
                                        </p></div>
                                   <input value={number} onChange={e => setNumber(e.target.value)} required type='text' name="contactNumber"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Country
                                        </p></div>
                                   <select required id="country" value={country} onChange={e => setCountry(e.target.value)} name="Country">
                                        {Countries.map((Country) => (
                                             <option value={Country.countryname}>{Country.countryname}</option>
                                        ))}
                                   </select>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             State
                                        </p></div>

                                   <input value={state} onChange={e => setState(e.target.value)} required type='text' name="State"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             City
                                        </p></div>
                                   <input value={city} onChange={e => setCity(e.target.value)} required type='text' name="city"></input>
                              </div>
                         </div>
                    </div>
                    <div className={` container ${styles.contactUs_section} ${styles.yellow}`} style={{ padding: '0px' }}>
                         <h1>
                              STARTUP DETAILS
                         </h1>
                         <hr className={styles.small__hr__color__2}></hr>
                    </div>

                    <div className={`container ${styles.contactUs_section}`}>
                         <div className="row">
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Capital Raised to Date (USD)
                                        </p>

                                   </div>
                                   <input type='text' name="CapitalRaisedtoDate"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Annual Recurring Revenue (USD)
                                        </p></div>
                                   <input type='text' name="AnnualRecurringRevenue"></input>
                              </div>

                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Current Burn Rate (USD)
                                        </p></div>
                                   <input type='text' name="CurrentBurnRate"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex', width: '380px' }}>
                                        <span><p></p></span>
                                        <p>
                                             Annual Contract Value (ongoing customer contract)
                                        </p></div>
                                   <input type='text' name="ACV"></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Runway (Months)
                                        </p></div>
                                   <input type='text' name='Runway'></input>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Number of Employees In Your Company
                                        </p></div>
                                   <select id="NOE" name="NOE">
                                        <option value="1-10">1-10</option>
                                        <option value="11-50">11-50</option>
                                        <option value="51-200">51-200 </option>
                                        <option value="200+">200+</option>
                                   </select>
                              </div>
                              <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.inputs__top__div}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Product Stage
                                        </p>
                                   </div>
                                   <div className={styles.radio__main__div}>
                                        <div>
                                             <input type="radio" id="html1" name="PS" value="PreSeed1"></input>
                                             <label for="html">Pre Seed</label>
                                        </div>

                                        <div>
                                             <input type="radio" id="html2" name="PS" value="PreSeed2"></input>
                                             <label for="html">Seed</label>
                                        </div>

                                        <div>
                                             <input type="radio" id="html3" name="PS" value="PreSeed3"></input>
                                             <label for="html">Series A</label>
                                        </div>

                                        <div>
                                             <input type="radio" id="html4" name="PS" value="PreSeed4"></input>
                                             <label for="html">Series B & beyond</label>
                                        </div>
                                   </div>
                              </div>


                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Website URL
                                        </p></div>
                                   <input required type='text' name='websiteURL' value={website} onChange={e => setWebsite(e.target.value)}></input>
                              </div>



                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Business Model
                                        </p></div>
                                   <textarea value={business} onChange={e => setBusiness(e.target.value)} required id="w3review" name="BM" rows="4" cols="50" />

                              </div>

                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Briefly Describe the Startup Problem Statement
                                        </p></div>
                                   <textarea value={statement} onChange={e => setStatement(e.target.value)} required id="w3review" name="BDSPS" rows="4" cols="50" />

                              </div>








                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p>*</p></span>
                                        <p>
                                             Linkedin Page Link
                                        </p></div>
                                   <input required type='text' name='linkedIn' value={linkedIn} onChange={e => setLinkedin(e.target.value)}></input>
                              </div>


                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Twitter Page Link
                                        </p></div>
                                   <input type='text' name='twitter'></input>
                              </div>

                              <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.inputs__top__div__2}`}>
                                   <div style={{ display: 'flex' }}>
                                        <span><p></p></span>
                                        <p>
                                             Crunchbase/Tracxn Link (Funding)
                                        </p></div>
                                   <input type='text' name='funding'></input>
                              </div>

                         </div>
                    </div>

                    <div className={`container ${styles.acd}`}>
                         <div className="row">
                              <div style={{ marginTop: '1rem' }}>
                                   <p style={{ color: 'white' }}>
                                        If you’d like to learn more about how we collect, process and safeguard your personal data, or to exercise your rights under applicable data protection legislation, please read our <a target="_blank" href="https://www.fisglobal.com/en-gb/privacy?_gl=1*i1iew6*_ga*NjEyNDc1OTcuMTY1Mjg4Njc2Mg..*_ga_SKZCV3S405*MTY2NTcyNjg3Mi44LjAuMTY2NTcyNjg3Mi4wLjAuMA..&_ga=2.229989080.539663151.1665726874-61247597.1652886762"> Privacy Notice</a>.

                                        <br></br> <br></br>
                                        <span style={{ fontSize: '18px', fontWeight: '500' }}>Marketing</span>
                                        <br></br>
                                        I consent to receiving marketing communications about FIS events, product information and industry insights via email, physical mail, telephone and/or text messages.
                                        I understand I will be able to withdraw my consent, change my <a href="https://discover.fisglobal.com/preferences-registration.html?utm_source=footerlink&utm_medium=fisglobal&utm_campaign=preferencecentergdpr " target="_blank">marketing preferences</a>.
                                   </p>

                                   <div>

                                        <div className={styles.radio__main__div__2}>
                                             <div>
                                                  <input type="radio" id="html5" name="PS" value={"Yes"} onChange={e => setCheckYes(e.target.value)}></input>
                                                  <label for="html">Yes</label>

                                                  <input type="radio" id="html6" name="PS" style={{ marginLeft: '50px' }} value={"No"} onChange={e => setCheckYes(e.target.value)}></input>
                                                  <label for="html">No</label>
                                             </div>


                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>


                    <Modal
                         className='modalBigOne'
                         size="lg"
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         show={show} onHide={handlClose}>
                         <div className={styles.container__contact__popup} >
                              <div className={styles.modalbody}>
                                   <div className={styles.header}>
                                        <h1>TERMS AND CONDITIONS</h1>

                                   </div>
                                   <div className={styles.content}>
                                        <p>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>Opening Page on site for Registrant to Complete First</span>
                                             <br></br>     <br></br>
                                             In consideration for the right to attend and participate in an upcoming “InnovateIN48 Partner Edition” dated August 2022 sponsored by Fidelity National Information Services, Inc. acting as a controller of personal data “InnovateIN48 Partner Edition”, and for other good and valuable considerations, the receipt and sufficiency of which are hereby acknowledged, I hereby agree on behalf of myself and my heirs, executors, personal representatives, and assigns, as follows:
                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}> 1) Consent to Process Data.</span>

                                             I hereby grant Fidelity National Information Services, Inc., and its officers, directors, employees, representatives, agents, subsidiaries, affiliates, successors, and assigns (collectively, “FIS”) and their approved third-party vendors or partners, my explicit consent to use or process my personal data (namely names, e-mail addresses, title, employer, portraits, and Works (as defined below)) in order to facilitate my attendance and participation in the “InnovateIN48 Partner Edition”.

                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>  2) Consent to Use of Likeness.</span>

                                             . I hereby grant Fidelity National Information Services, Inc., and its officers, directors, employees, representatives, agents, subsidiaries, affiliates, successors and assigns (collectively, “FIS”),  my explicit consent and the absolute right and permission to process and use my image, visual likeness, portrait, or photograph in all forms and media  taken in connection with  the “InnovateIN48 Partner Edition”  (including without limitation publications, websites, catalogues, brochures, books, magazines, photo exhibits, films, audio and/or video) (collectively, “Works”) for any and all purposes, including without limitation marketing, client events, training, public relations, and/or advertising, or for any other lawful purpose. I hereby waive all my rights to inspect and approve the Works, their use, or such written copy as may be used in connection therewith. I understand that FIS is not obligated to use any of the Works and shall make that determination at its discretion. I agree that all right, title, and interest in and to all Works and any reproductions or derivative work thereof shall be the exclusive property of FIS. I understand that FIS may keep or use the Works and derivative works now and in the future. I further consent to the use of my name and biographical work-related material in connection with the Works. I agree that FIS does not owe me any compensation for the acts that I have consented to in this Consent and Release. I hereby release FIS and hold FIS harmless from any and all liability or legal responsibility that may arise from the acts that I have authorized or consented to herein.
                                             <br></br>
                                             I acknowledge I may withdraw consent for any of the above (as set out  in paragraphs 1 and 2) respectively at any time by emailing fis.global.marketing.and.communications@fisglobal.com by specifying my prior consent has been withdrawn for which purpose (“Withdrawal Procedure”).  My revocation of consent will not apply to Works or personal data that have already been published or processed as FIS will be unable to control the use of already published or processed material.


                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>       3)	Acknowledgement.</span>


                                             I acknowledge and agree that I am not acquiring any right, title, or interest in, to or under, any copyright, trademark, trade name, trade secret, or other intellectual property right in the “INNOVATEIN48 Partner Edition”, the content and information presented at the “INNOVATEIN48 Partner Edition”, and all materials distributed at or in connection with the “INNOVATEIN48 Partner Edition” (collectively, “INNOVATEIN48 Partner Edition” Content) all of which are owned by FIS. I will not use, record, reproduce, rebroadcast, or allow any third party to do so, for any reason without the prior written permission of FIS.


                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>        4)	Feedback. </span>

                                             I acknowledge and agree that to the extent that I provide comments, suggestions, ideas, or other feedback (collectively, “Feedback”) to FIS during the Event, FIS shall be the exclusive owner of such Feedback and I hereby grant and assign all intellectual property rights thereto to FIS. FIS shall be free to disclose, reproduce, license, or otherwise distribute and exploit the Feedback provided as it sees fit, entirely without obligation or restriction of any kind on account of intellectual property rights or otherwise.

                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>     5)	Intellectual Property.</span>


                                             I acknowledge and agree that any creations or inventions and all related IP that I create as part of my participation in [the Event] (excluding any Works, “INNOVATEIN48 Partner Edition” Content, and Feedback as provided above) is and will remain my own original work (“Participant IP”) and does not infringe the intellectual property or proprietary rights of any third party.   I hereby grant to FIS a royalty free, perpetual, irrevocable, non-exclusive license to use at its discretion Participant IP without restriction.  Further, I acknowledge and agree that FIS may be developing now or in the future subject matter related or similar to or competitive with Participant IP and I will not hold FIS responsible for any liability or damages relating to the use of the Works, “INNOVATEIN48 Partner Edition” Content, Feedback, or Participant IP in accordance with this Consent and Release.

                                             <br></br> <br></br>
                                             <span style={{ fontWeight: "700", paddingBottom: '20px' }}>  6) 	General Liability Release and Indemnity.</span>


                                             I hereby release and hold harmless FIS from all actions, causes of action, claims, and demands for, upon, or by reason of any damage, loss, cost, or injury which I may hereafter sustain in connection with my attendance or participation in the “InnovateIN48 Partner Edition” . This Consent and Release applies to and includes without limitation all unknown, unforeseen, unanticipated, and unsuspected injuries, damages, losses, costs, expenses and liability and the consequences thereof, as well as those now disclosed and known to exist. I hereby agree to indemnify, defend, and hold harmless FIS from and against any and all loss, cost, damage, injury, and expense (including without limitation attorneys’ fees and costs) that FIS may incur in connection with my attendance or participation in the “InnovateIN48 Partner Edition” .

                                             <br></br> <br></br>

                                             I have read this Consent and Release, and I understand and agree to its contents. This Consent and Release is not restricted by time or geographic location.
                                        </p>
                                   </div>

                                   <div className={styles.footer}>
                                        <button onClick={sendEmail} >
                                             Agree
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </Modal>


                    <div className='conatiner'>
                         <div className='row' style={{ margin: '0' }}>
                              <div className='col-lg-12 col-md-12 col-sm-12' style={{ textAlign: 'center', marginTop: '4rem' }}>

                                   <SubmitButton />
                              </div>
                         </div>
                    </div>






               </form>
          </>
     )
}

export default ContactForm