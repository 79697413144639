import React from 'react';
import styles from "./EventTwenty.module.css"

function EventTwenty() {

     return (
          <div id='event' style={{ backgroundColor: '#191819' }}>
               <div className={`container-fluid ${styles.event_section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                              <h1>2022 EVENT</h1>
                         </div>
                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                              <hr className={styles.small__hr__color}></hr>
                         </div>

                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                              <p>
                                   The 2022 Partner Edition was focused on APAC startups with three broader themes. Startups pitched their standalone solution
                                   <span className={styles.break}> <br></br></span>
                                   or leveraged integration with current FIS APIs and products during the 48 Hours Ideathon. And what we witnessed were &nbsp;  <span className={styles.break}> <br></br></span>extraordinary innovative solutions from across all themes.

                              </p>
                         </div>
                    </div>
               </div>


               <div className={`container-fluid ${styles.three__main__Section}`}>
                    <div className="row" >
                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>

                              <img src="./assets/images/3_bottom.png" alt="das" />
                              <h1>
                                   Data innovation
                              </h1>
                              <br></br>

                         </div>

                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>
                              <img src="./assets/images/2_bottom.png" alt="das" />
                              <h1>
                                   AI in fintech
                              </h1>
                              <br></br>

                         </div>

                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>
                              <img src="./assets/images/1_bottom.png" alt="das" />
                              <h1>
                                   Digital assets
                              </h1>
                              <br></br>

                         </div>
                    </div>
               </div>


               <div className={styles.twentyhr__div} style={{ margin: '0%', padding: '0%', backgroundColor: '#191819' }}>
                    <hr className={styles.twentyhr}></hr>
               </div>


               <div className={`container-fluid ${styles.event_section}`}>
                    <div className="row">



                         <div className={styles.number__div}>
                              <ul className={styles.number__ul} >

                                   <li className={styles.number__li}>
                                        <img src="./assets/images/Group 185.png" alt="das" />
                                   </li>

                                   <li className={styles.number__li}>
                                        <img className={styles.unique_image_size} src="./assets/images/Group 187.png" alt="das" />
                                   </li>
                                   <li className={styles.number__li}>
                                        <img src="./assets/images/Group 184.png" alt="das" />
                                   </li>
                                   <li className={styles.number__li}>
                                        <img src="./assets/images/Group 182.png" alt="das" />
                                   </li>
                                   <li className={styles.number__li}>
                                        <img src="./assets/images/Group 183.png" alt="das" />
                                   </li>
                              </ul>
                         </div>


                    </div>
               </div>
          </div>
     )
}

export default EventTwenty