import React from 'react'
import LandingPageHeroBanner from '../../Components/LandingPageComponents/LandingHeroBanner/LandingPageHeroBanner'
import PartnerEdition from '../../Components/LandingPageComponents/PartnerEdition/PartnerEdition'
import CustomeNavbar from '../../Components/LandingPageComponents/Navbar/CustomeNavbar'
import EventTwenty from '../../Components/LandingPageComponents/2022Events/EventTwenty'
import LaunchEventGradiantSection from '../../Components/LandingPageComponents/LaunchEventGradiantSection/LaunchEventGradiantSection'
import LandingPageFooter from '../../Components/LandingPageComponents/LandingPageFooter/LandingPageFooter'
import LetsTalk from '../../Components/LandingPageComponents/LetsTalk/LetsTalk'
import PartnerThanks from '../../Components/LandingPageComponents/PartnerThanks/PartnerThanks'
import RoundWinners from '../../Components/LandingPageComponents/RoundWinners/RoundWinners'
function LandingPage() {
     return (
          <>
               <CustomeNavbar />
               <LandingPageHeroBanner />
               <PartnerEdition />
               <EventTwenty />
               <LaunchEventGradiantSection />
               <RoundWinners />
               <LetsTalk />
               <PartnerThanks />
               <LandingPageFooter />
          </>
     )
}

export default LandingPage