import React from 'react'
import styles from "./LetsTalk.module.css"
import { Link } from 'react-router-dom'

function LetsTalk() {
     return (
          <>
               <div className={`container-fluid ${styles.Gradiant__Section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.Gradiant__Section__inner}`}>
                              <div className={`container-fluid ${styles.event_section}`}>
                                   <div className="row">
                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                                             <h1>Let's Talk</h1>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                                             <hr className={styles.small__hr__color}></hr>
                                        </div>

                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                                             <p>
                                                  Together, we can work to solve your business goals. Please provide your email address below

                                                  <span className={styles.break}> <br></br></span>
                                                  and  a representative will reach out to you soon.
                                             </p>

                                             <br></br>
                                             <Link to='/contact'>
                                                  <button className={styles.contacUs}> Contact Us </button>
                                             </Link>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default LetsTalk