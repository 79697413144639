import React from 'react'
import styles from "./HeroBanner.module.css"

function HeroBanner() {
     return (
          <>

               <div className={styles.black__div}>

               </div>
               <div className={`container-fluid ${styles.heroImage}`}>
                    <div className="row">
                         <div className="col-lg-6 col-sm-12 col-md-12">
                              <div >
                                   <div className={styles.heroText}>
                                        <div className={styles.div_p__ibo}>
                                             <h1 className={styles.h1__ibo1} style={{ textTransform: 'uppercase' }}>Sessions, Workshops: <span className={styles.h1__ibo1__span}>Launch Event</span></h1>
                                             <h1 className={styles.h1__ibo}>
                                                  INNOVATEIN48 PARTNER EDITION
                                             </h1>
                                        </div>
                                        <br></br>

                                        <div className={styles.div_p__ibo}>
                                             <p className={styles.p__ibo} >
                                                  Get on-demand access to sessions from the fintech industry leaders.
                                             </p>
                                        </div>


                                   </div>
                              </div>
                         </div>

                         <div className={`col-lg-6 col-sm-12 col-md-1 ${styles.image__div__banner}`}>
                              <img src="./assets/images/inovation3.png" alt="sa" />
                         </div>
                    </div>
               </div>
          </>
     )
}

export default HeroBanner