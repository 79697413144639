import React from 'react'
import styles from "./LandingPageFooter.module.css"
import { Link } from 'react-router-dom'
function Footer() {
     return (
          <>
               <div className={`container-fluid ${styles.container__footer_main}`}>
                    <div className={`row ${styles.row__footer_main}`}>
                         <div className='col-lg-4 col-md-6 col-sm-12'>
                              <h1 className={styles.h1__footer}>
                                   ADVANCING THE  <br></br>WAY THE  WORLD <br></br>  PAYS, BANKS AND <br></br> INVESTS
                              </h1>
                              <hr className={styles.footer__hr__small}></hr>
                         </div>

                         <div className='col-lg-4 col-md-6 col-sm-12'>
                              <h1 className={styles.h1__footer}>
                                   HAVE ANY QUESTIONS?
                              </h1>


                              <p className={styles.footer__p__p1}>Send your questions to</p>
                              <h6 className={styles.footer__h6}>IN48Partner@fisglobal.com</h6>
                              <h6 className={styles.footer__h6__2}>Stay Connected</h6>

                              <div style={{ display: 'flex' }}>
                                   <a target="_blank"     href="https://www.facebook.com/FIStoday/"><img className={styles.social__images1} src="./assets/images/facebook.png" alt="" /></a>
                                   <a  target="_blank"    href="https://twitter.com/fisglobal"><img className={styles.social__images2} src="./assets/images/twitter.png" alt="" /></a>
                                   <a  target="_blank"    href="https://www.linkedin.com/company/fis/posts/?feedView=all"><img className={styles.social__images3} src="./assets/images/Linkedin.png" alt="" /></a>
                                   <a target="_blank"     href="https://www.instagram.com/fis_global/"><img className={styles.social__images4} src="./assets/images/instta.png" alt="" /></a>
                                   <div className={styles.utube__div}>
                                        <a target="_blank"     href="https://www.youtube.com/user/FISTVToday"><img className={styles.social__images5} src="./assets/images/youtube.png" alt="" /></a>
                                   </div>
                              </div>
                         </div>

                         <div className='col-lg-4 col-md-6 col-sm-12'>
                              <h1 className={styles.h1__footer}>
                                   GET IN TOUCH
                              </h1>
                              <h6 className={styles.footer__h6}>We are here to help you and your <br></br> business. Contact us using the
                              button
                              <br></br>
                                   below.</h6>
                              <Link to='/contact'>
                                   <button className={styles.contacUs}> Learn More </button>
                              </Link>
                         </div>
                    </div>
               </div>
               <div className={`container-fluid ${styles.container__footer_main_bottom}`}>
                    <div className={`row ${styles.row__footer_main_bottom}`}>
                         <div className='col-lg-12 col-md-12 col-sm-12'>
                              <h6 className={styles.bottom__p}>©FIS 2022. Advancing the way the world pays, banks and invests™</h6>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default Footer