import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./AllSpeakerDetails.module.css";

function AllSpeakerDetails({ infooo }) {

     const [show, setShow] = useState(false);
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     return (
          <>

               <div onClick={handleShow} className={styles.dot}>
                    <h6 className={styles.lengthName}> +{infooo.speaker.length - 4}</h6>
               </div>
               <Modal show={show} onHide={handleClose} className={styles.allSpaker_modal_header}>
                    <Modal.Header >
                         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                              <h3>
                                   Speakers
                              </h3>
                              <i onClick={handleClose} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                         </div>
                    </Modal.Header>
                    <Modal.Body className={styles.allSpaker_modal_body}>
                         {infooo.speaker.map((item) => {
                              return (
                                   <>
                                        <div className='container-fluid' style={{ padding: '0', margin: '0' }}>
                                             <div className='row'>
                                                  <div className='col-lg-2 col-sm-12 col-md-12' style={{ marginTop: '1rem', paddingLeft: '2rem' }}>
                                                       <img className={styles.pop__up__speaker__small_image} src={item.imageURL} alt='' />
                                                  </div>

                                                  <div className='col-lg-8 col-sm-12 col-md-12' style={{ marginLeft: '1rem' }}>
                                                       <div style={{ display: 'flex' }}>
                                                            <h6 style={{ paddingTop: '1.6rem' }} className={styles.speaker___h6__inner}>{item.speakerName}</h6>
                                                            {item.linkedIn && <a href={item.linkedIn} target='_blank'>  <img src="./assets/images/LinkedIn.png" style={{ width: '20px', height: '20px', cursor: 'pointer', marginTop: '1.6rem', marginLeft: '1rem' }} alt="" />
                                                            </a>}
                                                       </div>


                                                       <p className={styles.speaker___p__inner}> {item.designation}</p>

                                                       <br></br>
                                                  </div>
                                             </div>
                                             <hr className={styles.hr__popup__speaker_info}></hr>
                                        </div>

                                   </>
                              );
                         })}

                    </Modal.Body>

               </Modal>
          </>
     )
}

export default AllSpeakerDetails