import React from 'react'
import styles from "./HeroBanner.module.css"



function LandingPageHeroBanner() {
     return (
          <>

               <div className={styles.heroImage}>
                    <div className={styles.heroText}>
                         <img className={styles.inovation} src="./assets/images/inovation.png" alt="" />
                         <h1 className={styles.h1__ibo}>INNOVATE THE NEXT FINTECH DISRUPTION
                         </h1>
                         <br></br>
                    </div>
               </div>
          </>
     )
}

export default LandingPageHeroBanner