import React from 'react';
import styles from "./Navbar.module.css"
import { Link } from 'react-router-dom'

function CustomeNavbar() {


     return (
          <>
               <nav className="navbar navbar-expand-lg main__navbar navbar-dark" >
                    <Link to='/lp'>
                         <a className="navbar-brand" >
                              <div className={styles.card}>
                                   <img src="./assets/images/fullLogoGreen.png" className={styles.imgage__one} alt="Card Back" />
                                   <img src="./assets/images/fullLogoWhite.png" className={styles.imgtop} alt="Card Front" />
                              </div>
                         </a>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                         <span className="navbar-toggler-icon" style={{ color: 'white' }}></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                         <ul className="navbar-nav mr-auto">
                              <li className="nav-item active">
                                   <a href='#event'>
                                        <a className="nav-link">2022 Event</a>
                                   </a>
                              </li>
                              <li className="nav-item active">
                                   <a href='#aboutSection'>
                                        <a className="nav-link">About Event</a>
                                   </a>
                              </li>

                              <li className="nav-item active">
                                   <a href='#roundWinner'>
                                        <a className="nav-link">Winning Team</a>
                                   </a>
                              </li>
                         </ul>
                         <div className="form-inline my-2 my-lg-0">
                              <a style={{ textDecoration: 'none' }} href="https://www.fisglobal.com/en/contact-us" target='_blank'>     <button className={styles.contacUs}>  Contact us </button> </a>
                         </div>
                    </div>
               </nav>
          </>
     )
}

export default CustomeNavbar