import React, { useState } from 'react'
import styles from "./FeaturedSession.module.css"
import Modal from 'react-bootstrap/Modal';

function FeaturedSession() {


     const [show, setShow] = useState(false);
     const [show2, setShow2] = useState(false);
     const [show3, setShow3] = useState(false);
     const [show4, setShow4] = useState(false);
     const handlClose = () => setShow(false);
     const handlShow = () => setShow(true);
     const handlClose2 = () => setShow2(false);
     const handlShow2 = () => setShow2(true);
     const handlClose3 = () => setShow3(false);
     const handlShow3 = () => setShow3(true);
     const handlClose4 = () => setShow4(false);
     const handlShow4 = () => setShow4(true);



     return (
          <>


               <div className={`container-fluid ${styles.filetrBar}`}>
                    <div className="row">
                         <div className="col-lg-12 col-md-12 col-sm-12">
                              <h3 className={styles.titleName}>Featured Sessions</h3>
                              <hr className={styles.small__hr__color}></hr>
                         </div>
                    </div>
               </div>



               <div className={`container-fluid ${styles.video__section}`}>
                    <div className='row'>
                         <div className={`col-lg-3 col-md-12 col-sm-12 ${styles.container__image}`}>
                              <div className={styles.Text__to__display__div}>
                                   <h1 className={styles.Text__to__display}>Key Note Session: Vishad Gupta, Head, Global Delivery Organization, India & Philippines at FIS </h1>
                              </div>
                              <div className={styles.middle}>
                                   <div className={styles.text}>
                                        <div>
                                             <h6>Key Note Session: Vishad Gupta, Head, Global Delivery Organization, India & Philippines at FIS </h6>
                                             <br></br>
                                             <p>
                                                  Introduction of the FIS InnovateIN48 Partner Edition, the motivation behind the event and the opportunities for APAC startups.
                                             </p>
                                             <br></br>

                                             <button onClick={handlShow}>
                                                  Watch Now</button>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className={`col-lg-3 col-md-12 col-sm-12 ${styles.container__image}`}>
                              <div className={styles.Text__to__display__div}>
                                   <h1 className={styles.Text__to__display}>Key Note Session: Sopnendu Mohanty, Chief FinTech Officer, Monetary Authority of Singapore</h1>
                              </div>
                              <div className={styles.middle}>
                                   <div className={styles.text}>
                                        <div>
                                             <h6>Key Note Session: Sopnendu Mohanty, Chief FinTech Officer, Monetary Authority of Singapore</h6>
                                             <br></br>
                                             <p>
                                                  For fintechs, the future is promising. But the future also brings increased exposure to regulatory requirements, sanctions, and legal actions. Here’s a brief look at the fintech risk landscape and how fintechs can thrive in a more regulated business environment with our keynote speaker.
                                             </p>
                                             <br></br>

                                             <button onClick={handlShow2}>
                                                  Watch Now</button>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className={`col-lg-3 col-md-12 col-sm-12 ${styles.container__image}`} style={{ margin: '0', padding: '0' }}>
                              <div className={styles.Text__to__display__div}>
                                   <h1 className={styles.Text__to__display}>Introduction to FIS Lines of Business - Banking, Merchant, Capital Markets</h1>
                              </div>
                              <div className={styles.middle}>
                                   <div className={styles.text}>
                                        <div>
                                             <h6>Introduction to FIS Lines of Business - Banking, Merchant, Capital Markets</h6>
                                             <br></br>
                                             <p>
                                                  The panel discussion provides a high-level overview of the FIS key lines of business. You will hear the FIS India leadership team and understand key product lines and their respective geographical presence. Glimpse into the historical evolution as well as the future product roadmaps. This session will help you to understand key FIS terminologies and further position your startup in the FIS ecosystem.
                                             </p>
                                             <br></br>

                                             <button onClick={handlShow3}>
                                                  Watch Now</button>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className={`col-lg-3 col-md-12 col-sm-12 ${styles.container__image}`}>
                              <div className={styles.Text__to__display__div}>
                                   <h1 className={styles.Text__to__display}>FIS Strategic Innovation Programs & Beyond</h1>
                              </div>
                              <div className={styles.middle}>
                                   <div className={styles.text}>
                                        <div>
                                             <h6>FIS Strategic Innovation Programs & Beyond</h6>
                                             <br></br>
                                             <p>
                                                  FIS strategic innovation programs promote partner collaboration. This current Panel discussion will touch upon FIS Fintech advocacy, accelerato program, ventures, and alliance partner programs.


                                             </p>
                                             <br></br>

                                             <button onClick={handlShow4}>
                                                  Watch Now
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <Modal
                    className='modalBigOne'
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show} onHide={handlClose}>
                    <div className={styles.container__contact__popup} >

                         <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                              <i onClick={handlClose} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                         </div>
                         <div className={styles.modalbody}>
                              <div className="embed-responsive embed-responsive-21by9">
                                   <iframe src='https://www.youtube.com/embed/rh-yQobJy14' allowFullScreen></iframe>
                              </div>
                         </div>
                    </div>
               </Modal>


               <Modal
                    className='modalBigOne'
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show2} onHide={handlClose2}>
                    <div className={styles.container__contact__popup} >

                         <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                              <i onClick={handlClose2} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                         </div>
                         <div className={styles.modalbody}>
                              <div className="embed-responsive embed-responsive-21by9">
                                   <iframe src='https://www.youtube.com/embed/cizy87NvYCQ' allowFullScreen></iframe>
                              </div>
                         </div>
                    </div>
               </Modal>


               <Modal
                    className='modalBigOne'
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show3} onHide={handlClose3}>
                    <div className={styles.container__contact__popup} >

                         <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                              <i onClick={handlClose3} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                         </div>
                         <div className={styles.modalbody}>
                              <div className="embed-responsive embed-responsive-21by9">
                                   <iframe src='https://www.youtube.com/embed/YushlmBQ2Vk' allowFullScreen></iframe>
                              </div>
                         </div>
                    </div>
               </Modal>



               <Modal
                    className='modalBigOne'
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show4} onHide={handlClose4}>
                    <div className={styles.container__contact__popup} >

                         <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                              <i onClick={handlClose4} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                         </div>
                         <div className={styles.modalbody}>
                              <div className="embed-responsive embed-responsive-21by9">
                                   <iframe src='https://www.youtube.com/embed/dTZhiQCbyDI' allowFullScreen></iframe>
                              </div>
                         </div>
                    </div>
               </Modal>
          </>
     )
}

export default FeaturedSession