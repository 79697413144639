import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import InfoContainerAll from "../InfoContainer/InfoContainerAll";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ info, session2023 }) {
  const [value, setValue] = useState(0);
  const [innovation, setInovation] = useState([]);
  const [AI, setAI] = useState([]);
  const [assets, setAssets] = useState([]);
  const [dataInovation, setDataInovation] = useState([]);
  const [workshop, setWorkshop] = useState([]);

  // ------ new states added here -------
  const [mainStage, setMainStage] = useState([]);
  const [embeddedFinance, setEmbeddedFinance] = useState([]);

  const innovationfilteredData = info?.filter((tag) => {
    // return tag.tag?.includes("FIS & Innovation" && "Main Stage");

    // old code
    // return tag.tag?.includes("FIS & Innovation") || tag.tag?.includes("Main Stage");

    // updated code
    return tag.tag?.includes("FIS & Innovation");
  });

  const AIfilteredData = info?.filter((tag) => {
    return tag.tag?.includes("AI In Fintech");
  });

  const assetsfilteredData = info?.filter((tag) => {
    return tag.tag?.includes("Digital Assets");
  });

  const dataInovationfilteredData = info?.filter((tag) => {
    return tag.tag?.includes("Data Innovation");
  });

  const WorkShaopfilteredData = info?.filter((tag) => {
    return tag.tag?.includes("Workshop");
  });

  // ------- new methods added here -----------
  const mainStagefilteredData = info?.filter((tag) => {
    return tag.tag?.includes("Main Stage");
  });

  const embeddedFinancefilteredData = info?.filter((tag) => {
    return tag.tag?.includes("Embedded Finance");
  });

  useEffect(() => {
    setInovation(innovationfilteredData);
    setAI(AIfilteredData);
    setAssets(assetsfilteredData);
    setDataInovation(dataInovationfilteredData);
    setWorkshop(WorkShaopfilteredData);
    setMainStage(mainStagefilteredData);
    setEmbeddedFinance(embeddedFinancefilteredData);
  }, [""]);

  const ref = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box style={{width:"100%"}}>
      <Box style={{ borderBottom: 1, borderColor: "divider",width:"100%"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          style={{width:"100%"}}
        >
          <Tab
            label={
              <>
                <div>All</div>
              </>
            }
            {...a11yProps(0)}
          />

          {!session2023 && (
            <Tab
              label={
                <>
                  <div>FIS & Innovation</div>
                </>
              }
              {...a11yProps(1)}
            />
          )}

          <Tab
            label={
              <>
                <div>AI in Fintech</div>
              </>
            }
            {...a11yProps(1)}
          />

          <Tab
            label={
              <>
                <div>Digital Assets</div>
              </>
            }
            {...a11yProps(1)}
          />

          <Tab
            label={
              <>
                <div>Data Innovation</div>
              </>
            }
            {...a11yProps(1)}
          />

          <Tab
            label={
              <>
                <div>Workshop</div>
              </>
            }
            {...a11yProps(1)}
          />

          {session2023 && (
            <Tab
              label={
                <>
                  <div>Main Stage</div>
                </>
              }
              {...a11yProps(1)}
            />
          )}

          {session2023 && (
            <Tab
              label={
                <>
                  <div>Embedded Finance</div>
                </>
              }
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {info.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>

      {!session2023 && (
        <TabPanel value={value} index={1}>
          {innovation.map((infooo) => (
            <InfoContainerAll infooo={infooo} />
          ))}
        </TabPanel>
      )}

      <TabPanel value={value} index={session2023 ? 1 : 2}>
        {AI.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>

      <TabPanel value={value} index={session2023 ? 2 : 3}>
        {assets.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>

      <TabPanel value={value} index={session2023 ? 3 : 4}>
        {dataInovation.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>

      <TabPanel value={value} index={session2023 ? 4 : 5}>
        {workshop.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>

      {session2023 && <TabPanel value={value} index={5}>
        {mainStage.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>}

      {session2023 && <TabPanel value={value} index={6}>
        {embeddedFinance.map((infooo) => (
          <InfoContainerAll infooo={infooo} />
        ))}
      </TabPanel>}
    </Box>
  );
}
