const info = [
  {
    _id: '1',
    time: '02:30 PM',
    date: 'Sat 02:30 PM - 1 Session',
    bigDate: '20 Aug, 02:30 PM - 03:00 PM',
    bannerImage: './assets/images/asdsa.jpg',
    tag: 'FIS & Innovation',
    heading: 'Key Note Session: Vishad Gupta, Head, Global Delivery Organization, India & Philippines at FIS ',
    description: "Introduction of the FIS InnovateIN48 Partner Edition, the motivation behind the event and the opportunities for APAC startups.  ",
    tutorialLink: 'https://www.youtube.com/embed/rh-yQobJy14',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Vishad Gupta',
        designation: 'Senior VP & Head, Global Delivery. at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/1321_8294_976787001659337484.png',
        linkedIn: 'https://www.linkedin.com/in/vishad-gupta-1301a56/',
      },
    ]
  },


  {
    _id: '2',
    time: '03:00 PM',
    date: 'Sat 02:30 PM - 1 Session',
    bigDate: '20 Aug,03:00 PM - 04:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3489_5450_404989001660134968.jpeg',
    tag: 'FIS & Innovation',
    heading: 'Introduction to FIS Lines of Business - Banking, Merchant, Capital Markets ',
    description: "The panel discussion provides a high-level overview of the FIS key lines of business. You will hear the FIS India leadership team and understand key product lines and their respective geographical presence. Glimpse into the historical evolution as well as the future product roadmaps. This session will help you to understand key FIS terminologies and further position your startup in the FIS ecosystem.",
    tutorialLink: 'https://www.youtube.com/embed/YushlmBQ2Vk',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Vikash Sharma',
        designation: 'Senior Vice President, IT Division Executive at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4120_6953_351845001659443843.png',
        linkedIn: 'https://www.linkedin.com/in/vikash-sharma-933a8a19/',
      },
      {
        _id: '3',
        speakerName: 'Ravi Saraswat',
        designation: 'Senior Director, Development at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/1041_5904_020340001659435264.png',
        linkedIn: 'https://www.linkedin.com/in/ravi-saraswat-a79a007/',
      },
      {
        _id: '4',
        speakerName: 'Kausik Chatterjee',
        designation: 'Senior Director, Technology – Capital Markets at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3857_6442_195530001660827096.png',
        linkedIn: 'https://www.linkedin.com/in/kausikchatterjee-insead/',
      },

    ]
  },


  {
    _id: '3',
    time: '04:00 PM',
    date: 'Sat 04:00 PM - 1  Session',
    bigDate: '20 Aug,04:00 PM - 05:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/4983_7157_863124001660134995.jpeg',
    tag: 'FIS & Innovation',
    heading: 'FIS Developer Experience: Building on FIS APIs',
    description: "Each FIS Line of business offers unique ways for partners to augment and build on the FIS APIs. The Code Connect Marketplace is a self-service hub that allows users to learn and try APIs that FIS has to offer. The Marketplace includes hundreds of APIs that span a wide variety of categories such as Payments, Banking, Loyalty, Wealth, Card Management, and a variety of other needs. Our API catalogue is ever-growing to help our customers rapidly innovate and better their solution offerings. FIS Code Connect provides the following benefits to partners: Enables easier, real-time access to your core banking data. Simplifies the integration effort needed to build and deploy new solutions, increasing speed to market. Empowers creativity and rapid innovation. Provides the ability to differentiate your products from the competition, grow your customer base, and create new revenue sources. Enables third-party integrations and extends clients’ functional capabilities. Enables the creation of new lines of business. ",
    tutorialLink: 'https://www.youtube.com/embed/50vsCH0qIek',
    speaker: [

      {
        _id: '2',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },

      {
        _id: '2',
        speakerName: 'Arun Raju',
        designation: 'Senior Director at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2420_7990_953435001659435404.png',
        linkedIn: 'https://www.linkedin.com/in/mr-arun-raju/',
      },
      {
        _id: '3',
        speakerName: 'Prashant Setia',
        designation: 'Development Manager at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2955_6848_822761001660619747.png',
        linkedIn: 'https://www.linkedin.com/in/prashantsetia/',
      },
      {
        _id: '4',
        speakerName: 'Pravin Kumar',
        designation: 'Director Development API Gateway Platform',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/1006_9192_560589001660654150.png',
        linkedIn: 'https://www.linkedin.com/in/pravindeo/',
      },
      {
        _id: '5',
        speakerName: 'Michelle Henrich',
        designation: 'API Product Manager at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3290_6735_359950001660829674.png',
        linkedIn: 'https://www.linkedin.com/in/michelle-henrich/',
      },
      {
        _id: '6',
        speakerName: 'Christopher Constantinou',
        designation: 'FIS',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4983_9000_819678001660916707.png',
        linkedIn: ' https://www.linkedin.com/in/christopher-constantinou-3268a66a/',
      },
      {
        _id: '7',
        speakerName: 'Jennifer Childress',
        designation: 'Senior Product Manager at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3679_6503_485848001660112299.png',
        linkedIn: 'https://www.linkedin.com/in/jenniferannchildress/',
      },
      {
        _id: '8',
        speakerName: 'Pallavi Sher',
        designation: 'Senior Product Manager, Merchant Solutions APIs at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4902_5525_402754001659453981.png',
        linkedIn: 'https://www.linkedin.com/in/pallavisher/',
      },
    ]
  },

  {
    _id: '4',
    time: '05:15 PM',
    date: 'Sat 05:15 PM - 1  Session',
    bigDate: '20 Aug,05:15 PM - 06:15 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/4867_6114_927890001659531097.jpeg',
    tag: 'FIS & Innovation',
    heading: 'FIS Strategic Innovation Programs & Beyond',
    description: "FIS strategic innovation programs promote partner collaboration. This current panel discussion will touch upon FIS fintech advocacy, accelerator program, ventures, and alliance partner programs.",
    tutorialLink: 'https://www.youtube.com/embed/dTZhiQCbyDI',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Chris Barry',
        designation: 'Product Manager, Innovation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4920_6354_840519001659436974.png',
        linkedIn: 'https://www.linkedin.com/in/chbarry/',
      },
      {
        _id: '3',
        speakerName: 'Mukul Agrawal',
        designation: 'Alliances Head, Banking Solutions, Asia Pacific at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2035_5275_334224001659445957.png',
        linkedIn: 'https://www.linkedin.com/in/mukul-agrawal-26021b1/',
      },
      {
        _id: '4',
        speakerName: 'John Cooney',
        designation: 'Vice President Tech Innovation & Modernization',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/4144_8622_454831001660113705.png',
        linkedIn: 'https://www.linkedin.com/in/averyjohn/',
      },
      {
        _id: '5',
        speakerName: 'Edward Barker',
        designation: 'VP, Future Exploration & Venture Investments, at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3044_6039_268542001660130758.png',
        linkedIn: 'https://www.linkedin.com/in/edbarker/',
      },

    ]
  },

  {
    _id: '5',
    time: '06:15 PM',
    date: 'Sat 06:15 PM - 1  Session',
    bigDate: '20 Aug, 06:15 PM - 07:15 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3978_5119_242025001659531452.jpeg',
    tag: 'Digital Assets',
    heading: 'Digital Assets',
    description: "As we explore more digital environments with fewer limitations and boundaries, we are entering a new world of blockchain, cryptocurrencies, NFTs and more. These innovations are a vital aspect of the virtual economy that is increasingly at the heart of the metaverse. This panel discussion will help startups understand the FIS Web3 innovation landscape, current partners and areas of focus.  ",
    tutorialLink: 'https://www.youtube.com/embed/XbCPkbzGzL4',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'John Avery',
        designation: 'Product Head, Capital Markets, Crypto & Digital As at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3807_9250_032129001659446787.png',
        linkedIn: 'https://www.linkedin.com/in/averyjohn/',
      },

      {
        _id: '3',
        speakerName: 'Sai Prasad Raut',
        designation: 'Technology Advisor - Crypto and Web 3 at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4692_7564_716904001659447211.png',
        linkedIn: 'https://www.linkedin.com/in/saiprasad-raut-857a154/',
      },
      {
        _id: '4',
        speakerName: 'Zhiyi You',
        designation: 'Innovation Lead - Crypto Product Strategy at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4771_9112_250331001659446907.png',
        linkedIn: 'https://www.linkedin.com/in/zhiyiyou1/',
      },
    ]
  },

  {
    _id: '6',
    time: '07:15 PM',
    date: 'Sat 07:15 PM - 1  Session',
    bigDate: '20 Aug,07:15 PM - 08:15 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/4340_9846_578690001659531403.jpeg',
    tag: 'Data Innovation',
    heading: 'Data Innovation',
    description: "The financial sector is incredibly data-intensive, and the challenge has been to find ways to harness the data to drive business growth. This session will help startups to understand the current opportunities in data innovation. ",
    tutorialLink: 'https://www.youtube.com/embed/OXvUGiqE7uk',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Daniel Wakeman',
        designation: 'SVP Technology Development at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/1714_8753_325384001659448394.png',
        linkedIn: 'https://www.linkedin.com/in/dswakeman/',
      },
      {
        _id: '3',
        speakerName: 'Justine Chen',
        designation: 'Product Head at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2545_5664_999290001659450223.png',
        linkedIn: 'https://www.linkedin.com/in/justinechen1/',
      },
      {
        _id: '4',
        speakerName: 'Alexis Suer',
        designation: 'Head of Enterprise Data Governance at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/1441_7092_422101001660123865.png',
        linkedIn: 'https://www.linkedin.com/in/alexissuer/',
      },
      {
        _id: '5',
        speakerName: 'Amit Aggarwal',
        designation: 'Head of Partner Innovation, at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2257_6888_100374001660131405.png',
        linkedIn: 'https://www.linkedin.com/in/amit-aggarwal-25167495/',
      },
    ]
  },

  {
    _id: '7',
    time: '08:15 PM',
    date: 'Sat 08:15 PM - 1  Session',
    bigDate: '20 Aug,08:15 PM - 09:15 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3396_9177_999619001659531242.jpeg',
    tag: 'AI In Fintech',
    heading: 'AI in Fintech',
    description: "Financial companies rely on artificial intelligence to improve everything related to regulatory compliance, lending decision-making, fraud detection, credit risk assessment, wealth management, and beyond. This session covers the current work in Regtech, Risktech, and ESG as well as broader applications of AI in the fintech space at FIS Global.",
    tutorialLink: 'https://www.youtube.com/embed/gB4PeKFTkEc',
    speaker: [


      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'David Berglund',
        designation: 'SVP, Global Head of Artificial Intelligence at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2259_6296_304157001659680899.png',
        linkedIn: 'https://www.linkedin.com/in/dberglund/',
      },
      {
        _id: '3',
        speakerName: 'Dr Ranadhir Ghosh',
        designation: 'AI/ML Quantitave Analyst / Specialist at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4797_9161_846714001659450447.png',
        linkedIn: 'https://www.linkedin.com/in/dr-ranadhir-ghosh-bbb7b827/',
      },

      {
        _id: '4',
        speakerName: 'Andrew Ciafardini',
        designation: 'SVP, Corporate Affairs & CSO at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/1995_6886_735308001659681072.png',
        linkedIn: 'https://www.linkedin.com/in/andrewciafardini/',
      },
      {
        _id: '5',
        speakerName: 'Benjamin Wellmann',
        designation: 'Sr. Director Venture Investments at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2034_6891_682066001659450741.png',
        linkedIn: 'https://www.linkedin.com/in/bwellmann/',
      },
    ]
  },

  {
    _id: '9',
    time: '11:30 AM',
    date: 'Sun 11:30 AM - 1  Session',
    bigDate: '21 Aug,11:30 AM - 12:15 PM',
    bannerImage: './assets/images/Key-Note-Session-by-Sopnendu-Mohanty.jpg',
    tag: 'FIS & Innovation',
    heading: 'Key Note Session: Sopnendu Mohanty, Chief FinTech Officer, Monetary Authority of Singapore',
    description: "For fintechs, the future is promising. But the future also brings increased exposure to regulatory requirements, sanctions, and legal actions. Here’s a brief look at the fintech risk landscape and how fintechs can thrive in a more regulated business environment with our keynote speaker. ",
    tutorialLink: 'https://www.youtube.com/embed/cizy87NvYCQ',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Sopnendu Mohanty',
        designation: 'CTO at Monetary Authority of Singapore',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4990_5818_175629001659544269.png',
        linkedIn: 'https://www.linkedin.com/in/sopnendu/',
      },
    ]
  },

  {
    _id: '15',
    bannerImage: './assets/images/AFI.jpg',
    tag: 'FIS & Innovation',
    heading: 'IFSCA - Accelerating Fintech Innovation',
    description: "Key note on how India's integrated regulator is accelerating fintech innovation. Understand fintech entity framework, innovation sandboxes, cross-border regulatory collaborations, and trends in fintech Innovation. Take an innovation journey from open finance, open health, open commerce, and open data. ",
    tutorialLink: 'https://www.youtube.com/embed/kReJvLRk9o0',
    speaker: [

      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Joseph Joshy ',
        designation: 'Chief Technology Officer',
        imageURL: './assets/images/Joseph.jpg',
        linkedIn: 'https://www.linkedin.com/in/josephjoshy/',
      },



    ]
  },

  {
    _id: '10',
    time: '02:00 PM',
    date: 'Sun 02:00 PM - 1  Session',
    bigDate: '21 Aug,02:00 PM - 03:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3145_6460_621286001659532387.jpeg',
    tag: 'FIS & Innovation',
    heading: 'Identity',
    description: "Identity is the foundation for building the next fintech disruption. In this session, we will touch upon the evolution of identity and its future from Web 2.0 to Web 3.0, and how identity innovation has been reshaping fintech disruptions. Discover building blocks of nextgen identity management platforms from industry experts.  ",
    tutorialLink: 'https://www.youtube.com/embed/kjccatGVjiw',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Sai Prasad Raut',
        designation: 'Technology Advisor - Crypto and Web 3 at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4692_7564_716904001659447211.png',
        linkedIn: 'https://www.linkedin.com/in/saiprasad-raut-857a154/',
      },
      {
        _id: '3',
        speakerName: 'Amit Goel',
        designation: 'India Head at Prove',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2803_6136_439665001659452254.png',
        linkedIn: 'https://www.linkedin.com/in/amitpayments/',
      },
      {
        _id: '4',
        speakerName: 'Ravikant Agarwal ',
        designation: 'VP - Strategy, Polygon',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2599_5690_760765001659499367.png',
        linkedIn: 'https://www.linkedin.com/in/ravikantagrawal/',
      },
      {
        _id: '5',
        speakerName: 'Shiv Agarwal ',
        designation: ' CEO at Earth ID',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2709_9514_862131001660840552.png',
        linkedIn: 'https://www.linkedin.com/in/shiv-aggarwal/',
      },

    ]
  },

  {
    _id: '11',
    time: '03:00 PM',
    date: 'Sun 03:00 PM - 1  Session',
    bigDate: '21 Aug,03:00 PM - 04:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/2761_9538_950302001659963664.jpeg',
    tag: 'FIS & Innovation',
    heading: 'Web3 the Wonderland',
    description: "Web3 is a new, novel, and highly active area of socio-technical development built upon blockchain technologies. It has numerous unique areas of scientific investigation, ranging from cryptoeconomic coordination mechanisms to unique sites of social order. With the recent explosion of decentralized autonomous organizations (DAOs) and other resilient, peer-to-peer technologies (DeFi, DeSci, NFTs, etc.), the Web3 Wonderland will help you learn about new and emerging social movements and technologies.  ",
    tutorialLink: 'https://www.youtube.com/embed/9R-bpq5WLbY',
    speaker: [


      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },



      {
        _id: '2',
        speakerName: 'Amit Mehra',
        designation: 'General Partner at Borderless Capital',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/4421_6282_513066001659499576.png',
        linkedIn: 'https://www.linkedin.com/in/aamehra/',
      },
      {
        _id: '3',
        speakerName: 'Prashant Setia',
        designation: 'Development Manager at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2955_6848_822761001660619747.png',
        linkedIn: 'https://www.linkedin.com/in/prashantsetia/',
      },
      {
        _id: '4',
        speakerName: 'Matt Woodward',
        designation: 'Head of Developer Relations at Swirlds Labs',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3330_9617_460830001659519867.png',
        linkedIn: 'https://www.linkedin.com/in/woodwardmatt/',
      },
      {
        _id: '5',
        speakerName: 'Francesco Coacci',
        designation: 'Developer Evangelist at Swirlds Labs',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3784_7549_550874001659520152.png',
        linkedIn: 'https://www.linkedin.com/in/francesco-coacci/',
      },

      {
        _id: '6',
        speakerName: 'Ravikant Agarwal ',
        designation: 'VP - Strategy, Polygon',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/2599_5690_760765001659499367.png',
        linkedIn: 'https://www.linkedin.com/in/ravikantagrawal/',
      },

    ]
  },

  {
    _id: '12',
    time: '04:00 PM',
    date: 'Sun 04:00 PM - 1  Sessions',
    bigDate: '21 Aug,04:00 PM - 07:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3072_7121_668858001659532435.jpeg',
    tag: 'Workshop',
    heading: 'Hedera Workshop',
    description: "Come and learn how to develop for the next generation of the internet. Hedera is the most used enterprise-grade public network for you to make your digital world exactly as it should be – yours. In this session, you’ll get an introduction to development on Hedera (incl. an overview of core concepts & use-cases) that will ensure your next Web3 project is a success. ",
    tutorialLink: 'https://www.youtube.com/embed/zMISF8iwZ54',
    speaker: [
      {
        _id: '1',
        speakerName: 'Matt Woodward',
        designation: 'Head of Developer Relations at Swirlds Labs',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3330_9617_460830001659519867.png',
        linkedIn: 'https://www.linkedin.com/in/woodwardmatt/',
      },
      {
        _id: '2',
        speakerName: 'Francesco Coacci',
        designation: 'Developer Evangelist at Swirlds Labs',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3784_7549_550874001659520152.png',
        linkedIn: 'https://www.linkedin.com/in/francesco-coacci/',
      },
    ]
  },

  {
    _id: '13',
    time: '04:00 PM',
    date: 'Sun 04:00 PM - 1  Sessions',
    bigDate: '21 Aug,04:00 PM - 07:00 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/1240_7078_164342001660915291.jpeg',
    tag: 'Workshop',
    heading: 'Earth ID Workshop',
    description: "Earth ID is an award-winning decentralized identity platform that allows secure and frictionless exchange and verification of identity information Earth ID helps organizations reduce identity frauds and the associated costs while empowering users with data ownership, privacy and consent. One of their key missions is to enable frictionless and secure access to Web 3.0 and Metaverse-based ecosystems.",
    tutorialLink: 'https://www.youtube.com/embed/R30lOADW1yw',
    speaker: [

      {
        _id: '1',
        speakerName: 'Shiv Aggrawal ',
        designation: 'Co-founder at  Earth ID',
        imageURL: './assets/images/Shiv Aggarwal.jfif',
        linkedIn: 'https://www.linkedin.com/in/shiv-aggarwal/',
      },
      {
        _id: '2',
        speakerName: 'Rahul Asati ',
        designation: 'Chief Architect at Earth ID',
        imageURL: './assets/images/Rahul Asati.jfif',
        linkedIn: ' https://www.linkedin.com/in/rahulasati/',
      },

    ]
  },

  {
    _id: '14',
    time: '04:30 PM',
    date: 'Sun 04:30 PM - 1  Session',
    bigDate: '21 Aug, 04:30 PM - 05:30 PM',
    bannerImage: 'https://cdn.hubilo.com/sessionbanner/3406161/3115_8072_644891001659532465.jpeg',
    tag: 'FIS & Innovation',
    heading: 'Fintech Global Landscape',
    description: "In recent years, fintech has begun to deliver significant value to both consumers and businesses and has reshaped how some interact with financial services. While the fintech sector is going from strength to strength, there are a lot of possibilities for innovation, learn from the global leaders on Innovation across Data, AI and Web3.  ",
    tutorialLink: 'https://www.youtube.com/embed/IB6VQZSpRTs',
    speaker: [
      {
        _id: '1',
        speakerName: 'Prashant Mane',
        designation: 'Director Client Relation at FIS Global',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/350/2846_9307_496364001656937780.png',
        linkedIn: 'https://www.linkedin.com/in/prashantmane/?originalSubdomain=in',
      },
      {
        _id: '2',
        speakerName: 'Abhishant Pant',
        designation: 'Founder at The Fintech Meetup',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3993_7150_931389001659451151.png',
        linkedIn: 'https://www.linkedin.com/in/nabilfmanji/',
      },
      {
        _id: '3',
        speakerName: 'Syed Musheer Ahmed',
        designation: 'Founder & Managing Director- Finstep Asia',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3609_7710_098376001659452439.png',
        linkedIn: 'https://linkedin.com/in/syedmusheer',
      },
      {
        _id: '4',
        speakerName: 'Prasanna Lohar',
        designation: 'Director, at Association of Emerging Technologies',
        imageURL: 'https://cdn.hubilo.com/speaker/3406161/150/3158_5696_812768001660277954.png',
        linkedIn: 'https://www.linkedin.com/in/prasannalohar/',
      },
    ]
  },

]

export default info