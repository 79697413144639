import React from 'react'
import styles from './ThanksText.module.css'
import { Link } from "react-router-dom"
function ThanksText() {
     return (
          <>
               <div className={`container-fluid ${styles.thanks__main__section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.Thanks__heading__Section}`}>
                              <div>
                                   <h1>Thank you</h1>
                              </div>
                              <div className={styles.paraSection__div}>
                                   <p>We have received your request and will respond to you shortly.</p>
                                   <br></br><br></br>

                                   <p>
                                        FIS is a global leader in financial services technology, with a focus on retail and institutional banking, payments, asset and wealth management, risk and compliance, consulting and outsourcing solutions. Through the depth and breadth of our solutions portfolio, global capabilities and domain expertise, FIS serves more than 20,000 clients in over 130 countries. Headquartered in Jacksonville, Fla., FIS employs more than 47,000 people worldwide and holds leadership positions in payment processing, financial software and banking solutions. Providing software, services and outsourcing of the technology that empowers the financial world, FIS is a Fortune 500 company and is a member of Standard & Poor’s 500® Index.

                                   </p>
                                   <br></br>

                                   <p>
                                        For more information about FIS, go back to <span>
                                             <a href=' https://www.fisglobal.com/en/'>
                                                  Home
                                             </a>
                                        </span>.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default ThanksText