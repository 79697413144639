import React from 'react'
import styles from "./LaunchEventGradiantSection.module.css";
import { Link } from 'react-router-dom'
function LaunchEventGradiantSection() {
     return (
          <>
               <div className={`container-fluid ${styles.Gradiant__Section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.Gradiant__Section__inner}`}>
                              <div className={`container-fluid ${styles.event_section}`}>
                                   <div className="row">
                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                                             <h1>FIS InnovateIN48 2022 Launch Event</h1>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                                             <hr className={styles.small__hr__color}></hr>
                                        </div>
                                        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                                             <p>
                                                  This two-day launch event was a prelude to the InnovateIN48 Ideathon. The event covered
                                                  <span className={styles.break}> <br></br></span>
                                                  an introduction to the FIS innovation ecosystem. Attendees learned about FIS
                                                  <span className={styles.break}> <br></br></span>
                                                  products and APIs, and also participated in various hands-on workshops.
                                             </p>
                                             <br></br>
                                             <Link to='/'>
                                                  <button className={styles.contacUs}> Learn more </button>
                                             </Link>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}
export default LaunchEventGradiantSection