import React from 'react';
import './App.css';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import LandingPage from './Screens/LandingPageScreen/LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ContactUsScreen from './Screens/ContactUsScreen/ContactUsScreen';
import ScrollToTop from './Components/ScroolToTop/ScrollToTop';
import ThanksScreen from './Screens/ThanksScreen/ThanksScreen';



function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/launchevent" element={<HomeScreen />} />
          <Route exact path="/thankyou" element={<ThanksScreen />} />
          <Route exact path="/lp" element={<LandingPage />} />
          <Route exact path="/contact" element={<ContactUsScreen />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
