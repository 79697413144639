import React from 'react';
import styles from "./PartnerEdition.module.css"

function PartnerEdition() {

     return (
          <div id='aboutSection' >
               <div className={`container-fluid ${styles.PartnerEdition_section}`}>
                    <div className="row">
                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.heading__Section}`}>
                              <h1>About InnovateIN48 Partner Edition</h1>
                         </div>
                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                              <hr className={styles.small__hr__color}></hr>
                         </div>

                         <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.para__Section}`}>
                              <p>
                                   FIS InnovateIN48 Partner Edition is all about connecting external innovation with the FIS® ecosystem. Partner edition is a theme-based
                                   <span className={styles.break}> <br></br></span>
                                   Ideathon that acts as a gateway program to curate and connect partners with the FIS line of business, accelerator,  <span className={styles.break}> <br></br></span> ventures, alliance partner program and more.
                              </p>
                         </div>
                    </div>
               </div>



               <div className={`container-fluid ${styles.three__main__Section}`}>
                    <div className="row">
                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>

                              <img className={styles.threeSection__image} src="./assets/images/3_top.png" alt="das" />
                              <h1>
                                   Educate
                              </h1>
                              <br></br>
                              <p>
                                   The Partner Edition mega-launch event is a prelude to the InnovateIN48 Ideathon. The learning event covers an introduction to the FIS innovation ecosystem. Partners learn about FIS innovation programs, products and APIs, and participate in various hands-on workshops. This event is designed to help partners prepare for the Ideathon.
                              </p>
                         </div>

                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>
                              <img className={styles.threeSection__image} src="./assets/images/2_top.png" alt="das" />
                              <h1>
                                   Innovate and ideate
                              </h1>
                              <br></br>
                              <p>
                                   This 48-hour ideation event celebrates our culture of co-creation, innovation and collaboration. The event encourages experiential learning and harnesses the possibilities of emerging technology. Partners work with industry and FIS mentors to innovate the next fintech disruption by connecting partner solutions with the FIS ecosystem.
                              </p>
                         </div>

                         <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.threeSection}`}>
                              <img className={styles.threeSection__image} src="./assets/images/1_top.png" alt="das" />
                              <h1>
                                   Co-create and connect
                              </h1>
                              <br></br>
                              <p>
                                   Get visibility to key innovation programs and stakeholders. Present and discuss strategic alignments during demo days and discovery sessions. Work with FIS Innovation ambassadors post-ideation to convert potential to actual opportunities. Benefit from the FIS innovation partner ecosystem sandbox and FIS developer experience programs.


                              </p>
                         </div>
                    </div>
               </div>

          </div>
     )
}

export default PartnerEdition