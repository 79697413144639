import React, { useState } from 'react'
import styles from "./InfoContainer.module.css"
import Modal from 'react-bootstrap/Modal';
import AllSpeakerDetails from '../AllSpeakerDetails/AllSpeakerDetails';
import Tooltip from '@mui/material/Tooltip';

function InfoContainerAll({ infooo }) {
     const [show, setShow] = useState(false);
     const [show2, setShow2] = useState(false);
     const handlClose = () => setShow(false);
     const handlShow = () => setShow(true);
     const handlClose2 = () => setShow2(false);
     const handlShow2 = () => setShow2(true);
     return (
          <>
               <div className={`container-fluid ${styles.container__time}`}>
                    <div className="row">
                         <div className="col-lg-12 col-md-12 col-sm-12">

                         </div>
                         <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                         </div>
                    </div>
               </div>



               <div className={`container-fluid ${styles.container__box}`}>
                    <div className="row">
                         <div className="col-lg-6 col-md-12 col-sm-12">
                              <img className={`img-fluid ${styles.banner__image}`} src={infooo.bannerImage} alt='banner Image' />
                         </div>
                         <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.container__box__inner__info}`}>

                              <div>
                                  {infooo.tag && <div className={styles.tags}>
                                        <p> {infooo.tag}</p>
                                   </div>}
                              </div>
                              <div className={styles.info__text__box}>
                                   <h3>
                                        {infooo.heading}
                                   </h3>
                                   <p className={styles.info__text__box__p}>
                                        {infooo.description}
                                   </p>
                              </div>
                              <div className={`${styles.bottom__div__info} ${styles.bottom__div__info__responsive}`}>
                                   <div className={styles.avatar__span}>
                                        {infooo.speaker.filter((item, index) => index < 4).map((filteredItem) => {
                                             return (
                                                  <Tooltip
                                                       classes={{
                                                            tooltip: styles.customTooltip,

                                                       }}
                                                       title={
                                                            <div className={styles.main__div__popup}>
                                                                 <div>
                                                                      <div>
                                                                           <img className={styles.pop__up__speaker__big_image} src={filteredItem.imageURL} alt='' />

                                                                      </div>
                                                                      <div style={{ display: 'flex', width: '100%', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                                                                           <h2 className={styles.pop__up__speaker__name}>
                                                                                {filteredItem.speakerName}
                                                                           </h2>

                                                                           {filteredItem.linkedIn && <a href={filteredItem.linkedIn} target='_blank'>  <img src="./assets/images/LinkedIn.png" style={{ width: '20px', height: '20px', cursor: 'pointer', marginTop: '1.4rem', marginLeft: '1rem' }} alt="" />
                                                                           </a>}
                                                                      </div>
                                                                      <div>
                                                                           <h6 className={styles.pop__up__speaker__disc}>
                                                                                {filteredItem.designation}
                                                                           </h6>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       }
                                                  >
                                                       <img className={styles.spakers} src={filteredItem.imageURL} alt='imageURL' />
                                                  </Tooltip>
                                             );
                                        })}



                                        <span>
                                             {
                                                  infooo.speaker?.length > 4 ? <AllSpeakerDetails infooo={infooo} /> : null
                                             }
                                        </span>

                                   </div>


                                   <div>
                                        <button onClick={handlShow2} className={`${styles.Watch__button} ${styles.responsiveButton}`}>
                                             Watch Session
                                        </button>
                                   </div>
                              </div>



                              <div className={styles.more__info}>
                                   <p onClick={handlShow}> More info &nbsp; <i class="fa-solid fa-arrow-down"></i></p>
                              </div>






                         </div>
                    </div>


                    <Modal show={show} onHide={handlClose}>

                         <div className='container' style={{ marginTop: '41px' }}>
                              <div className="row" style={{ backgroundColor: 'black' }}>

                                   <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                                        <i onClick={handlClose} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                                   </div>

                                   <div className='col-lg-12 col-md-12 col-sm-12' style={{ padding: '0' }}>
                                        <img className="img-fluid" src={infooo.bannerImage} alt='banner Image' />
                                   </div>
                                   <div className='col-lg-12 col-md-12 col-sm-12' style={{ background: 'black' }}>


                                        <div>
                                             <div className={styles.tags} style={{ marginBottom: '1rem ', marginTop: '1rem' }}>
                                                  <p> {infooo.tag}</p>
                                             </div>
                                        </div>

                                        <div className={styles.info__text__box____2}>
                                             <h3>
                                                  {infooo.heading}
                                             </h3>
                                             <p className={styles.info__text__box__p2}>
                                                  {infooo.description}
                                             </p>
                                        </div>
                                        <div className={styles.bottom__div__info}>
                                             <div className={styles.avatar__span}>
                                                  {infooo.speaker.filter((item, index) => index < 4).map((filteredItem) => {
                                                       return (
                                                            <Tooltip
                                                                 classes={{
                                                                      tooltip: styles.customTooltip,

                                                                 }}
                                                                 title={
                                                                      <div className={styles.main__div__popup}>
                                                                           <div>
                                                                                <div>
                                                                                     <img className={styles.pop__up__speaker__big_image} src={filteredItem.imageURL} alt='' />

                                                                                </div>
                                                                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                                                                                     <h2 className={styles.pop__up__speaker__name}>
                                                                                          {filteredItem.speakerName}
                                                                                     </h2>

                                                                                     <a href={filteredItem.linkedIn} target='_blank'>  <img src="./assets/images/LinkedIn.png" style={{ width: '20px', height: '20px', cursor: 'pointer', marginTop: '1.4rem', marginLeft: '1rem' }} alt="" />
                                                                                     </a>
                                                                                </div>
                                                                                <div>
                                                                                     <h6 className={styles.pop__up__speaker__disc}>
                                                                                          {filteredItem.designation}
                                                                                     </h6>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 }
                                                            >
                                                                 <img className={`${styles.spacing__button} ${styles.spakers}`} src={filteredItem.imageURL} alt='imageURL' />
                                                            </Tooltip>
                                                       );
                                                  })}



                                                  <span>
                                                       {
                                                            infooo.speaker?.length > 4 ? <AllSpeakerDetails infooo={infooo} /> : null
                                                       }
                                                  </span>

                                             </div>


                                             <div>

                                                  <button onClick={handlShow2} className={styles.Watch__button}>
                                                       Watch Session
                                                  </button>


                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </Modal>








                    <Modal
                         className='modalBigOne'
                         size="lg"
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         show={show2} onHide={handlClose2}>
                         <div className={styles.container__contact__popup} >

                              <div style={{ width: '100%', height: 'auto', backgroundColor: 'transparent' }}>
                                   <i onClick={handlClose2} className={`fa-solid fa-square-xmark ${styles.vvvv}`}></i>
                              </div>
                              <div className={styles.modalbody}>
                                   <div className="embed-responsive embed-responsive-21by9">
                                       {infooo.tutorialLink === 'coming soon'? <img src="./assets/images/comingSoon.webp" alt="coming soon" style={{position: "absolute",top: "0",left: "0",width: "100%",height: "100%",right: "0",bottom:" 0",objectFit: "cover",}} />
                                       :<iframe src={infooo.tutorialLink} allowFullScreen></iframe>}
                                   </div>
                              </div>
                         </div>
                    </Modal>







               </div >




          </>
     )
}

export default InfoContainerAll



