import React, { useState } from 'react'
import BasicTabs from '../TabsSwitch/BasicTabs'
import styles from "./FilterSection.module.css"

// ----- dataDayOne is inported here ---
import info from "../../Data/dataDayOne";
import infoDayTwo from "../../Data/dataDayTwo";
import { Link, NavLink } from 'react-router-dom';

function FilterSection(props) {

     
     const [dayOne, setDayOne] = useState(true);
     const [dayTwo, setDayTwo] = useState(false);
     const [showIcon, setShowIcon] = useState(false);
     const [showIcon1, setShowIcon1] = useState(true);

     const shiftButtonOne = () => {
          setDayOne(true);
          setDayTwo(false);
          console.log("shiftButtonOne", dayOne, dayTwo)
     }

     const shiftButtonTwo = () => {
          setDayOne(false);
          setDayTwo(true);
          console.log("shiftButtonTwo", dayOne, dayTwo);
     }

     const buttonIconShow = () => {
          setShowIcon(prevCheck => !prevCheck);
          if(showIcon1 === true){
               setShowIcon1(prevCheck => !prevCheck);
          }
     }

     const buttonIconShow2 = () => {
          setShowIcon1(prevCheck => !prevCheck);
          if(showIcon === true){
               setShowIcon(prevCheck => !prevCheck);
          }
     }

     return (
          <>
            
            <section style={{overflow:'hidden'}} id='2022'>
               <div className={`container-fluid ${styles.filetrBarSession}`}>
                    <div className="row">
                         <div className="col-lg-12 col-md-12 col-sm-12">
                              <h3 className={styles.titleName}>Watch on demand Sessions</h3>
                              <hr className={styles.small__hr__color}></hr>
                         </div>
                    </div>
               </div>

               <div className={`containes ${styles.accordian__div}`}>
                    <div className='sd'>
                         <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                   <div class="accordion-header" id="headingOne">
                                        <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                             
                                             <div className={styles.accordian__title}>
                                                  <h5>
                                                       2022 Sessions
                                                  </h5>
                                             </div>

                                             <div className='d-flex align-items-cente'>
                                                  {
                                                       showIcon ?
                                                          <div className='d-flex align-items-center' style={{gap:"20px"}}>
                                                                  <NavLink to="/lp" target='_blank'>
                                                                    <i ata-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-solid fa-home ${styles.home}`} style={{color:"#ffffff"}}></i>
                                                                  </NavLink>
                                                                  
                                                                  <i ata-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-solid fa-minus ${styles.minus}`} onClick={buttonIconShow}></i>
                                                             </div>
                                                            :
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-sharp fa-solid fa-plus ${styles.sharp}`} onClick={buttonIconShow}></i>
                                                  }
                                             </div>
                                        </div>
                                   </div>
                                   <div id="collapseOne"
                                        className={`accordion-collapse collapse ${styles.collapse} ${showIcon?'show':''}`}
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className={`accordion-body ${styles.accordion_body}`}>
                                             <BasicTabs info = {info}/>
                                        </div>
                                   </div>
                              </div>

                         </div>

                    </div>
               </div>
            </section>

            <section style={{overflow:'hidden'}} id='2023'>
               <div className={`containes ${styles.accordian__div}`}>
                    <div className='sd'>
                         <div class="accordion" id="accordionExample2">
                              <div class="accordion-item">
                                   <div class="accordion-header" id="headingOne2">
                                        <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                             
                                             <div className={styles.accordian__title}>
                                                  <h5>
                                                       2023 Sessions
                                                  </h5>
                                             </div>

                                             <div className='d-flex align-items-cente'>
                                                  {
                                                       showIcon1 ?
                                                             <div className='d-flex align-items-center' style={{gap:"20px"}}>
                                                                 <a href='https://www.fisglobal.com/en/fisinnovatein48-partner-edition'>
                                                                  <i ata-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-solid fa-home ${styles.home}`} style={{color:"#ffffff"}}></i>
                                                                  </a>
                                                                  <i ata-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-solid fa-minus ${styles.minus}`} onClick={buttonIconShow2}></i>
                                                             </div>
                                                            :
                                                            <i data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className={`fa-sharp fa-solid fa-plus ${styles.sharp}`} onClick={buttonIconShow2}></i>
                                                  }
                                             </div>

                                        </div>
                                   </div>
                                   <div id="collapseOne2"
                                        className={`accordion-collapse collapse ${styles.collapse} ${showIcon1?'show':''}`}
                                        aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                                        <div className={`d-flex justify-content-center accordion-body ${styles.accordion_body}`}>
                                            <BasicTabs info = {infoDayTwo} session2023 = "session2023"/>
                                        </div>
                                   </div>
                              </div>

                         </div>

                    </div>
               </div>
            </section>

          </>
     )
}

export default FilterSection